import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import AddIcon from '@mui/icons-material/Add';
import { blue } from '@mui/material/colors';
import { postRequest,postWithLiveRequest } from '../Services/networkRequests';
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'date-fns';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const emails = ['Delivered'];

function SimpleDialog(props) {
  const { onClose, selectedValue, open, row ,setOpenPopUp} = props;
  const [showForm, setShowForm] = useState(false);
  const [ShowStatusList, setShowStatusList] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [status, setStatus] = useState('');
  const [trackingNumber, setTrackingNumber] = useState('');
  const [trackingUrl, setTrackingUrl] = useState('');

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = async (value) => {
    console.log('value', value);
    console.log('value', row);
    setStatus(value);
    let params ={
      OrderLimitedId: row.id,
    }
    const tempRes = await postWithLiveRequest('all-orders-delivered', params);
    if(tempRes.status === 200){
      toast(tempRes.data.message );
    }else{
      toast('Something went wrong' );
    }
    handleClose();
    props.refreshOrders();
    onClose(selectedValue);
  };
  const handleSubmit = async (e)=>{
    e.preventDefault();
    setShowForm(false);
    setShowStatusList(true);
    const formattedDate = format(startDate, 'yyyy-MM-dd HH:mm:ss');
    let params ={
      orderId: row.id,
      status: status.toLocaleLowerCase(),
      trackingId:trackingNumber,
      deliveryDate:formattedDate,
      trackingURL: trackingUrl,
    }
    const tempRes = await postWithLiveRequest('orderConfirmed', params);
    if(tempRes.status === 200){
      toast(tempRes.data.message );
      props.setOpenPopUp(false);
    }else{
      toast('Something went wrong' );
    }

  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Set Status</DialogTitle>
      {ShowStatusList &&
          <>
            <List sx={{ pt: 0 }}>
              {emails.map((email) => (
                  <ListItem disableGutters key={email}>
                    <ListItemButton onClick={() => handleListItemClick(email)}>
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                          <AddIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={email} />
                    </ListItemButton>
                  </ListItem>
              ))}

            </List>
          </>
      }

      {showForm &&
          <>
            <button className="btn btn-secondary w-25 m-auto" onClick={(e) => { setShowStatusList(true); setShowForm(false); }}>back</button>

            <div className="p-5">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="trackingNumber">Tracking Number</label>
                  <input type="text" className="form-control" id="trackingNumber" aria-describedby="emailHelp"
                         onChange={(e) => setTrackingNumber( e.target.value)}
                         placeholder="Tracking Number" required={true}/>

                </div>
                <div className="form-group">
                  <label htmlFor="trackingNumber">Tracking URL</label>
                  <input type="text" className="form-control" id="trackingUrl" aria-describedby="emailHelp"
                         onChange={(e) => setTrackingUrl( e.target.value)}
                         placeholder="Tracking Url" required={true}/>

                </div>
                <div className="form-group cstm-form-group">
                  <label htmlFor="deliveryDate" className="d-block">Delivery Date</label>
                  <DatePicker className="form-control w-100" selected={startDate} onChange={(date) => setStartDate(date)} />
                  {/*<input type="text" className="form-control" id="deliveryDate" placeholder="Delivery Date" onChange={(date) => setData({ ...data, deliveryDate:date})}/>*/}
                </div>
                <button type="submit" className="btn btn-primary mt-2 w-100" style={{backgroundColor: '#bbdefb', color: '#1e88e5',borderColor:'#bbdefb'}}>Submit</button>
              </form>
            </div>
          </>
      }

    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function PopUp(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[0]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    props.setOpenPopUp(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <ToastContainer />
      {/* <Typography variant="subtitle1" component="div">
        Selected: {selectedValue}
      </Typography>
      <br />
      <Button variant="outlined" onClick={handleClickOpen}>
        Open simple dialog
      </Button> */}
      <SimpleDialog
        selectedValue={selectedValue}
        open={props.openPopUp}
        onClose={handleClose}
        row={props.row}
        status={props.status}
        refreshOrders={props.refreshOrders}
      />
    </div>
  );
}
