import React, { useState, useEffect,useRef } from 'react';
import style from './catalogPiplineStatus.module.css';
import {getRequest, postRequest} from "../../Services/networkRequests";
import Spinner from 'react-bootstrap/Spinner';
import $ from 'jquery';
import 'datatables.net-bs5'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from "@mui/material/Button";
const CatalogPipelineStatus = () =>{
    const [data, setData] = useState([]);
    const [showData,setShowData]=useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [showPipelineLoader, setShowPipelineLoader] = useState(false);
    const [showRetryLoader, setShowRetryLoader] = useState(false);
    const [showFSLoader, setShowFSLoader] = useState(false);
    const [showBetaTransferLoader, setShowBetaTransferLoader] = useState(false);
    const [showLiveTransferLoader, setShowLiveTransferLoader] = useState(false);

    const dataTableRef = useRef(null);
    useEffect(() => {

        fetchData();
    }, []);
    useEffect(() => {
        // Initialize DataTable when the component mounts
        if (showData) {
            $(dataTableRef.current).DataTable({
                aaSorting: [] // Empty array disables initial sorting
            });
        }
    }, [showData]);

    const fetchData = async () => {
        setShowLoader(true);
        const result = await getRequest('/get-catalog-pipeline-list');
        if(result.data){
            setData(result.data);
        }else{
            toast('Something went wrong' );
        }
        setShowLoader(false);
        setShowData(true);

    };

    const handleTransferBetaStatusChange = async (e) => {
        e.preventDefault();
        const confirmed = window.confirm('Are you sure you want to Transfer?');

        if (!confirmed) {
            return;
        }

        setShowBetaTransferLoader(true);

        try {
            let req ={
                "command": "beta-data-replace"
            }

            const result = await postRequest('/trigger-command',req);

            if(result.status === 200){
                toast(result.data.message );

            }else{
                toast('Something went wrong' );
            }
        } catch (error) {
            console.error('Error:', error);
            toast('Something went wrong');
        } finally {
            setShowBetaTransferLoader(false);
        }

    };
    const handleTransferLiveStatusChange = async (e) => {
        e.preventDefault();
        const confirmed = window.confirm('Are you sure you want to Transfer?');

        if (!confirmed) {
            return;
        }

        setShowLiveTransferLoader(true);

        try {
            let req ={
                "command": "live-data-replace"
            }
            const result = await postRequest('/trigger-command',req);

            if(result.status === 200){
                toast(result.data.message );

            }else{
                toast('Something went wrong' );
            }
        } catch (error) {
            console.error('Error:', error);
            toast('Something went wrong');
        } finally {
            setShowLiveTransferLoader(false);
        }

    };
    const handleRunStatusChange = async (e) => {
        const confirmed = window.confirm('Are you sure you want to run the pipeline?');

        if (!confirmed) {
            return;
        }

        setShowPipelineLoader(true);

        try {
            let req = {
                "command": "start-catalog-local"
            };

            const result = await postRequest('/trigger-command', req);

            if (result.status === 200) {
                toast(result.data.message);
            } else {
                toast('Something went wrong');
            }
        } catch (error) {
            console.error('Error:', error);
            toast('Something went wrong');
        } finally {
            setShowPipelineLoader(false);
        }
    };
    const handleForceCheckStatusChange = async (e) => {
        e.preventDefault();
        setShowFSLoader(true);

        let req ={
            "command": "force-stop-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setShowFSLoader(false);
    };
    const handleRetryStatusChange = async (e) => {
        e.preventDefault();
        setShowRetryLoader(true);

        let req ={
            "command": "start-download-image"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setShowRetryLoader(false);

    };



    return(
        <div>
            <section className={`${style.status_listing_section}`}>
                <ToastContainer />
                <div className="container-fluid">

                    <div className={`${style.status_listing_card} card border-secondary mb-3`}>
                        <div className={`${style.status_listing_card_header} card-header `}>
                            <h1>Catalog Pipeline Status Listing</h1>
                        </div>
                        <div className="card-body">
                            <div className={`${style.status_listing}`}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text-start mb-2">
                                        <button type="button"
                                                className={`${style.cstm_btn} btn me-2`} onClick={(e) => handleRunStatusChange(e)}>

                                            {showPipelineLoader ? (
                                                <>
                                                    <Spinner
                                                        animation="border"
                                                        size="sm"
                                                        variant="light"
                                                    />
                                                </>
                                            ) : (
                                                'Run Pipeline'
                                            )}

                                        </button>
                                            <button type="button"
                                                    className={`${style.cstm_btn} btn me-2`} onClick={(e) =>handleTransferBetaStatusChange(e)}>
                                                {showBetaTransferLoader ? (
                                                    <>
                                                        <Spinner
                                                            animation="border"
                                                            size="sm"
                                                            variant="light"
                                                        />
                                                    </>
                                                ) : (
                                                    'Transfer To Beta Server'
                                                )}
                                            </button>
                                            <button type="button"
                                                    className={`${style.cstm_btn} btn me-2`} onClick={(e) =>handleTransferLiveStatusChange(e)}>
                                                {showLiveTransferLoader ? (
                                                    <>
                                                        <Spinner
                                                            animation="border"
                                                            size="sm"
                                                            variant="light"
                                                        />
                                                    </>
                                                ) : (
                                                    'Transfer To Live Server'
                                                )}
                                            </button>
                                        </div>
                                        {showData && (
                                            <>
                                                <div className="table-responsive">
                                                    <table
                                                        ref={dataTableRef}
                                                        className="table table-hover table-bordered status-table"
                                                    >
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Current Step</th>
                                                            <th scope="col">Error</th>
                                                            <th scope="col">Retry from breakpoint</th>
                                                            <th scope="col">Started at / Completed at </th>
                                                            <th scope="col">Error at / Retry at</th>
                                                            <th scope="col">Beta Transferred </th>
                                                            <th scope="col">Live Transferred </th>
                                                            <th scope="col">Force Stop </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {data !== null && data.length ? (
                                                            data.map((item, index) => (
                                                                <tr key={item.id}>

                                                                    <td>{item.status ? item.status:'N/A'}</td>
                                                                    <td>{item.current_step ? item.current_step:'N/A'}</td>
                                                                    <td>{item.error_message ? item.error_message:'N/A'}</td>
                                                                    <td>
                                                                            <button type="button"
                                                                                    className={`${style.cstm_btn} btn `} onClick={(e) =>handleRetryStatusChange(e)}>

                                                                                {showRetryLoader ? (
                                                                                    <>
                                                                                        <Spinner
                                                                                            animation="border"
                                                                                            size="sm"
                                                                                            variant="light"
                                                                                        />
                                                                                    </>
                                                                                ) : (
                                                                                    'Retry'
                                                                                )}
                                                                            </button>
                                                                    </td>
                                                                    <td>
                                                                        {item.created_at ? new Date(item.created_at).toLocaleString() : 'NA'} / {item.completed_at ? new Date(item.completed_at).toLocaleString() : 'NA'}
                                                                    </td>
                                                                    <td> {item.error_at ? new Date(item.error_at).toLocaleString() : 'NA'} /  {item.retry_at ? new Date(item.retry_at).toLocaleString() : 'NA'}</td>

                                                                    <td>
                                                                        {item.data_transfer_beta  === 1 ? 'Yes' : 'No'}

                                                                    </td>

                                                                    <td>
                                                                        {item.data_transfer  === 1 ? 'Yes' : 'No'}

                                                                    </td>
                                                                    <td>
                                                                        <button type="button"
                                                                                className={`${style.cstm_btn} btn `} onClick={(e) =>handleForceCheckStatusChange(e)}>
                                                                            {showFSLoader ? (
                                                                                <>
                                                                                    <Spinner
                                                                                        animation="border"
                                                                                        size="sm"
                                                                                        variant="light"
                                                                                    />
                                                                                </>
                                                                            ) : (
                                                                                'Stop'
                                                                            )}
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <></>
                                                        )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        )}

                                        {showLoader && (
                                            <>
                                                <div className="text-center pt-5">
                                                    <Spinner
                                                        animation="border"
                                                        role="status"
                                                        size="lg"
                                                        style={{
                                                            height: '5rem',
                                                            width: '5rem',
                                                        }}
                                                    >
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )
}
export default CatalogPipelineStatus;