import * as React from "react";
import { BrowserRouter as Router, Routes, Route, Outlet, Link } from "react-router-dom";
import { Login } from "./Pages/Login";
import { Register } from "./Pages/Register";
import { Home } from "./Pages/Home";
import ScraperStatus from "./Pages/scraperStatus/scraperStatus";
import EmptyLayout from "./Layouts/EmptyLayout";
import Layout from "./Layouts/Layout";
import AddCar from "./Pages/Cars/AddCar";
import AddCategory from "./Pages/Category/AddCategory";
import Actions from "./Pages/Actions/actions";
import NewBrandAction from "./Pages/Actions/NewBrandAction";

import DailyScrapperAction from "./Pages/Actions/dailyScrapperAction";
import BrandAmbassador from "./Pages/BrandAmbassador/BrandAmbassador";

import Brands from "./Pages/Brands/brands";
import CartBrandListing from "./Pages/cartBrandListing/cartBrandListing";
import OrderBrandListing from "./Pages/orderBrandListing/orderBrandListing";
import CatalogBrandListing from "./Pages/catalogBrandListing/catalogBrandListing";
import DailyBrandListing from "./Pages/dailyBrandListing/dailyBrandListing";
import CartScraper from "./Pages/cartScraper/cartScraper";
import CatalogScraperStatus from "./Pages/catalogScraper/catalogScraperStatus";
import OrderScraperStatus from "./Pages/orderScraper/orderScraperStatus";
import CatalogPipelineStatus from "./Pages/catalogPipelineStatus/catalogPipelineStatus";
import UserSection from "./Pages/userSection/userSection";
import OrderRefund from "./Pages/orderRefund/orderRefund";
import CatalogProducts from "./Pages/catalogProducts/catalogProducts";
import BrandDetails from "./Pages/BrandDetails/BrandDetails.jsx";
import ModelPipeline from './Pages/ModelPipeline/ModelPipeline.jsx';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Categories } from "./Pages/Category/Categories";

export default function App() {
  return (
    <div>
      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/add-car" element={<AddCar />} />
            <Route path="/add-car/:id" element={<AddCar />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/add-category" element={<AddCategory />} />
            <Route path="/add-category/:id" element={<AddCategory />} />
            <Route path="/actions" element={<Actions />} />
            <Route path="/new-brand-actions" element={<NewBrandAction />} />

            <Route path="/dailyScrapperAction" element={<DailyScrapperAction />} />
            <Route path="/BrandAmbassador" element={<BrandAmbassador />} />

            <Route path="/status-listing" element={<ScraperStatus />} />
            <Route path="/catalog-status-listing" element={<CatalogScraperStatus />} />
            <Route path="/order-status-listing" element={<OrderScraperStatus />} />
            <Route path="/brands" element={<Brands />} />
            <Route path="/cart-status-listing" element={<CartScraper />} />
            <Route path="/cart-brand-listing" element={<CartBrandListing />} />
            <Route path="/catalog-brand-listing" element={<CatalogBrandListing />} />
            <Route path="/daily-brand-listing" element={<DailyBrandListing />} />
            <Route path="/order-brand-listing" element={<OrderBrandListing />} />
            <Route path="/catalog-pipeline-status-listing" element={<CatalogPipelineStatus />} />
            <Route path="/user-section" element={<UserSection />} />
            <Route path="/order-refund" element={<OrderRefund />} />
            <Route path="/catalog-products" element={<CatalogProducts />} />
            <Route path="/brand-details" element={<BrandDetails />} />
            <Route path="/model-pipeline" element={<ModelPipeline />} />

            <Route path="*" element={<NoMatch />} />
          </Route>
          <Route path="/" element={<EmptyLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}