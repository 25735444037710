import React, { useState, useEffect,useRef } from 'react';
import style from './orderScraperStatus.module.css';
import {getRequest, postRequest} from "../../Services/networkRequests";
import Spinner from 'react-bootstrap/Spinner';
import $ from 'jquery';
import 'datatables.net-bs5'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const OrderScraperStatus = () =>{
    const [data, setData] = useState([]);
    const [showData,setShowData]=useState(false);
    const [showLoader, setShowLoader] = useState(true)
    const dataTableRef = useRef(null);
    useEffect(() => {

        fetchData();
    }, []);
    useEffect(() => {
        // Initialize DataTable when the component mounts
        if (showData) {
            $(dataTableRef.current).DataTable({
                aaSorting: [] // Empty array disables initial sorting
            });        }
    }, [showData]);



    const fetchData = async () => {
        // console.log('fetching');
        setShowLoader(true);
        const result = await getRequest('/get-order-scrapper-status');
        if(result.data){
            setData(result.data);
        }else{
            toast('Something went wrong' );
        }
        setShowLoader(false);
        setShowData(true);

    };
    const handleStatusChange = async (index) => {
        const updatedBrands = [...data];
        updatedBrands[index].brand.status = 1 - updatedBrands[index].brand.status; // Toggle the status
        setData(updatedBrands);
        const result = await postRequest('/change-order-scrapper-status',updatedBrands[index].brand);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }

    };



    return(
        <div>
            <section className={`${style.status_listing_section}`}>
                <ToastContainer />
                <div className="container-fluid">

                    <div className={`${style.status_listing_card} card border-secondary mb-3`}>
                        <div className={`${style.status_listing_card_header} card-header `}>
                            <h1>Order Scrapper Status Listing</h1>
                        </div>
                        <div className="card-body">
                            <div className={`${style.status_listing}`}>
                                <div className="row">
                                    <div className="col-md-12">
                                        {showData && (
                                            <>
                                                <div className="table-responsive">
                                                    <table
                                                        ref={dataTableRef}
                                                        className="table table-hover table-bordered status-table"
                                                    >
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Error</th>
                                                            <th scope="col">Button</th>
                                                            <th scope="col">Time Stamp</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {data !== null && data.length ? (
                                                            data.map((item, index) => (
                                                                <tr key={item.id}>
                                                                    <td>{item.name ? item.name :'N/A'}</td>
                                                                    <td>{item.status ? item.status:'N/A'}</td>
                                                                    <td>{item.error ? item.error:'N/A'}</td>
                                                                    <td>
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                role="switch"
                                                                                id={`flexSwitchCheck${index}`}
                                                                                checked={item.brand?.status  === 1}
                                                                                onChange={() => handleStatusChange(index)}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={`flexSwitchCheck${index}`}
                                                                            >
                                                                                {item.brand?.status  === 1 ? 'on' : 'off'}
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {item.created_at ? new Date(item.created_at).toLocaleString() : 'N/A'}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <></>
                                                        )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        )}

                                        {showLoader && (
                                            <>
                                                <div className="text-center pt-5">
                                                    <Spinner
                                                        animation="border"
                                                        role="status"
                                                        size="lg"
                                                        style={{
                                                            height: '5rem',
                                                            width: '5rem',
                                                        }}
                                                    >
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default OrderScraperStatus;