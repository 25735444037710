import React, { useState} from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Spinner from 'react-bootstrap/Spinner';
import { postRequest} from "../../Services/networkRequests";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Actions = ()=>{
    const titleStyle = { padding: '20px', width: '100%', margin: "0" }
    const [isLoadingBetaData, setIsLoadingBetaData] = useState(false)
    const [isLoadingScraperData, setIsLoadingScraperData] = useState(false)
    const [isLoadingLiveData, setIsLoadingLiveData] = useState(false)
    const [isburberryCatalog, setIsBurberryCatalog] = useState(false)
    const [isGapCatalog, setIsGapCatalog] = useState(false)
    const [isBananaCatalog, setIsBananaCatalog] = useState(false)
    const [isGucciCatalog, setIsGucciCatalog] = useState(false)
    const [isNikeCatalog, setIsNikeCatalog] = useState(false)
    const [isAdidasCatalog, setIsAdidasCatalog] = useState(false)
    const [isBershkaCatalog, setIsBershkaCatalog] = useState(false)
    const [isLululemonCatalog, setIsLululemonCatalog] = useState(false)
    const [isMangoCatalog, setIsMangoCatalog] = useState(false)
    const [isUniqloCatalog, setIsUiqloCatalog] = useState(false)
    // const [isForeverCatalog, setIsForeverCatalog] = useState(false)
    const [isOldNavyCatalog, setIsOldNavyCatalog] = useState(false)
    const [isLevisCatalog, setIsLevisCatalog] = useState(false)
    const [isHmCatalog, setIsHmCatalog] = useState(false)
    // const [isAsosCatalog, setIsAsosCatalog] = useState(false)
    const [isLacosteCatalog, setIsLacosteCatalog] = useState(false)
    const [isGivenchyCatalog, setIsGivenchyCatalog] = useState(false)
    const [isMassimoCatalog, setIsMassimoCatalog] = useState(false)
    // const [isSuperDryCatalog, setIsSuperDryCatalog] = useState(false)
    const [isZaraCatalog, setIsZaraCatalog] = useState(false)
    const [isAeCatalog, setIsAeCatalog] = useState(false)
    const [isMollyCatalog, setIsMollyCatalog] = useState(false)
    const [isantropologieCatalog, setisantropologieCatalog] = useState(false)
    const [isskimsCatalog, setisskimsCatalog] = useState(false)
    const [ispacsunCatalog, setispacsunCatalog] = useState(false)
    const [issabercrombieCatalog, setisabercrombieCatalog] = useState(false)




    
    const [isaritziaCatalog, setisaritziaCatalog] = useState(false)
    const [isediktedCatalog, setisediktedCatalog] = useState(false)
    const [isprincessCatalog, setisprincessCatalog] = useState(false)
    const [isselfieCatalog, setisselfieCatalog] = useState(false)

    const [farfetch, setfarfetch] = useState(false)

const runLocalScraper = async (e)=>{
        setIsLoadingScraperData(true);
        e.preventDefault();
    let req ={
        "command": "start-queues-action"
    }
    const result = await postRequest('/trigger-command',req);
    if(result.status === 200){
        toast(result.data.message );
    }else{
        toast('Something went wrong' );
    }
    setIsLoadingScraperData(false);
}
const runLiveScraper = (e)=>{
    e.preventDefault();
    alert('runLiveScraper');
}
const moveLocalData = async (e)=>{
        setIsLoadingBetaData(true);
    e.preventDefault();
    let req ={
        "command": "update-data-beta"
    }
    const result = await postRequest('/trigger-command',req);
    if(result.status === 200){
        toast(result.data.message );
    }else{
        toast('Something went wrong' );
    }
    setIsLoadingBetaData(false);



}
const moveLiveData = async (e)=>{
        e.preventDefault();
        setIsLoadingLiveData(true);
    let req ={
        "command": "update-data-live"
    }
    const result = await postRequest('/trigger-command',req);
    if(result.status === 200){
        toast(result.data.message );
    }else{
        toast('Something went wrong' );
    }
    setIsLoadingLiveData(false);


}



const farfetchCatalog = async (e)=>{
    e.preventDefault();
    setfarfetch(true);
    let req ={
        "command": "start-farfetch-catalog"
    }
    const result = await postRequest('/trigger-command',req);
    if(result.status === 200){
        toast(result.data.message );
    }else{
        toast('Something went wrong' );
    }
    setfarfetch(false);


}





    const burberryCatalog = async (e)=>{
        e.preventDefault();
        setIsBurberryCatalog(true);
        let req ={
            "command": "start-burberry-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsBurberryCatalog(false);


    }
    const gapCatalog = async (e)=>{
        e.preventDefault();
        setIsGapCatalog(true);
        let req ={
            "command": "start-gap-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsGapCatalog(false);


    }

    const aritziaCatalog = async (e)=>{
        e.preventDefault();
        setisaritziaCatalog(true);
        let req ={
            "command": "start-aritzia-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setisaritziaCatalog(false);


    }

    const princessCatalog = async (e)=>{
        e.preventDefault();
        setisprincessCatalog(true);
        let req ={
            "command": "start-princess-polly-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setisprincessCatalog(false);


    }
    
    const ediktedCatalog = async (e)=>{
        e.preventDefault();
        setisediktedCatalog(true);
        let req ={
            "command": "start-edikted-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setisediktedCatalog(false);


    }
    const selfieCatalog = async (e)=>{
        e.preventDefault();
        setisselfieCatalog(true);
        let req ={
            "command": "start-selfie-leslie-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setisselfieCatalog(false);


    }

    const bananaCatalog = async (e)=>{
        e.preventDefault();
        setIsBananaCatalog(true);
        let req ={
            "command": "start-banana-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsBananaCatalog(false);
    }
    const gucciCatalog = async (e)=>{
        e.preventDefault();
        setIsGucciCatalog(true);
        let req ={
            "command": "start-gucci-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsGucciCatalog(false);
    }
    const nikeCatalog = async (e)=>{
        e.preventDefault();
        setIsNikeCatalog(true);
        let req ={
            "command": "start-nike-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsNikeCatalog(false);
    }
    const adidasCatalog = async (e)=>{
        e.preventDefault();
        setIsAdidasCatalog(true);
        let req ={
            "command": "start-adidas-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsAdidasCatalog(false);
    }
    const bershkaCatalog = async (e)=>{
        e.preventDefault();
        setIsBershkaCatalog(true);
        let req ={
            "command": "start-bershka-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsBershkaCatalog(false);
    }
    const lululemonCatalog = async (e)=>{
        e.preventDefault();
        setIsLululemonCatalog(true);
        let req ={
            "command": "start-lululemon-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsLululemonCatalog(false);
    }
    const mangoCatalog = async (e)=>{
        e.preventDefault();
        setIsMangoCatalog(true);
        let req ={
            "command": "start-mango-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsMangoCatalog(false);
    }
    const uniqloCatalog = async (e)=>{
        e.preventDefault();
        setIsUiqloCatalog(true);
        let req ={
            "command": "start-uniqlo-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsUiqloCatalog(false);
    }
    // const foreverCatalog = async (e)=>{
    //     e.preventDefault();
    //     setIsForeverCatalog(true);
    //     let req ={
    //         "command": "start-forever-catalog"
    //     }
    //     const result = await postRequest('/trigger-command',req);
    //     if(result.status === 200){
    //         toast(result.data.message );
    //     }else{
    //         toast('Something went wrong' );
    //     }
    //     setIsForeverCatalog(false);
    // }
    const oldNavyCatalog = async (e)=>{
        e.preventDefault();
        setIsOldNavyCatalog(true);
        let req ={
            "command": "start-old-navy-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsOldNavyCatalog(false);
    }
    const levisCatalog = async (e)=>{
        e.preventDefault();
        setIsLevisCatalog(true);
        let req ={
            "command": "start-levi-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsLevisCatalog(false);
    }
    const hmCatalog = async (e)=>{
        e.preventDefault();
        setIsHmCatalog(true);
        let req ={
            "command": "start-hm-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsHmCatalog(false);
    }
    // const asosCatalog = async (e)=>{
    //     e.preventDefault();
    //     setIsAsosCatalog(true);
    //     let req ={
    //         "command": "start-asos-catalog"
    //     }
    //     const result = await postRequest('/trigger-command',req);
    //     if(result.status === 200){
    //         toast(result.data.message );
    //     }else{
    //         toast('Something went wrong' );
    //     }
    //     setIsAsosCatalog(false);
    // }
    const lacosteCatalog = async (e)=>{
        e.preventDefault();
        setIsLacosteCatalog(true);
        let req ={
            "command": "start-lacoste-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsLacosteCatalog(false);
    }
    const givenchyCatalog = async (e)=>{
        e.preventDefault();
        setIsGivenchyCatalog(true);
        let req ={
            "command": "start-givenchy-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsGivenchyCatalog(false);
    }
    const massimoCatalog = async (e)=>{
        e.preventDefault();
        setIsMassimoCatalog(true);
        let req ={
            "command": "start-massimo-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsMassimoCatalog(false);
    }
    // const superDryCatalog = async (e)=>{
    //     e.preventDefault();
    //     setIsSuperDryCatalog(true);
    //     let req ={
    //         "command": "start-super-dry-catalog"
    //     }
    //     const result = await postRequest('/trigger-command',req);
    //     if(result.status === 200){
    //         toast(result.data.message );
    //     }else{
    //         toast('Something went wrong' );
    //     }
    //     setIsSuperDryCatalog(false);
    // }
    const zaraCatalog = async (e)=>{
        e.preventDefault();
        setIsZaraCatalog(true);
        let req ={
            "command": "start-zara-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsZaraCatalog(false);
    }
    const aeCatalog = async (e)=>{
        e.preventDefault();
        setIsAeCatalog(true);
        let req ={
            "command": "start-ae-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsAeCatalog(false);


    }



    const mollyCatalog = async (e)=>{
        e.preventDefault();
        setIsMollyCatalog(true);
        let req ={
            "command": "start-hello-molly-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setIsMollyCatalog(false);


    }

    

    const antropologieCatalog = async (e)=>{
        e.preventDefault();
        setisantropologieCatalog(true);
        let req ={
            "command": "start-antropologie-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setisantropologieCatalog(false);


    }

    

    const skimsCatalog = async (e)=>{
        e.preventDefault();
        setisskimsCatalog(true);
        let req ={
            "command": "start-skims-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setisskimsCatalog(false);


    }

    const pacsunCatalog = async (e)=>{
        e.preventDefault();
        setispacsunCatalog(true);
        let req ={
            "command": "start-pacsun-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setispacsunCatalog(false);


    }


    const abercrombieCatalog = async (e)=>{
        e.preventDefault();
        setisabercrombieCatalog(true);
        let req ={
            "command": "start-abercrombie-catalog"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setisabercrombieCatalog(false);


    }

    
    return(
    <div>
        <ToastContainer />
            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                <h3 style={titleStyle}>Actions</h3>
            </Box>
            <Box component={'div'}  display={'flex'}>
                <Grid container spacing={2}>
                    {/* <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> runLocalScraper(e)}>
                                    {isLoadingScraperData ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Run Local Scrapers'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid> */}
                    {/*<Grid item md={3}>*/}
                    {/*    <Paper sx={{ width: '100%', mb: 2, padding:5}}>*/}
                    {/*        <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >*/}
                    {/*            <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> runLiveScraper(e)}>Run Live Scrapers</Button>*/}

                    {/*        </Box>*/}
                    {/*    </Paper>*/}
                    {/*</Grid>*/}
                    {/* <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> moveLocalData(e)}>

                                    {isLoadingBetaData ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'update Beta Data'
                                    )}
                                </Button>

                            </Box>
                        </Paper>
                    </Grid> */}
                    {/* <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> moveLiveData(e)}>

                                    {isLoadingLiveData ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Update Live Data'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid> */}



                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> farfetchCatalog(e)}>

                                    {farfetch ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start farfetch Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>








                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> aritziaCatalog(e)}>

                                    {isaritziaCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start artiza Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> ediktedCatalog(e)}>

                                    {isediktedCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start edikted Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> princessCatalog(e)}>

                                    {isprincessCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start princess Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> selfieCatalog(e)}>

                                    {isselfieCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start selfie Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> burberryCatalog(e)}>

                                    {isburberryCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Burberry Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> gapCatalog(e)}>

                                    {isGapCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start gap Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> bananaCatalog(e)}>

                                    {isBananaCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Banana Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> gucciCatalog(e)}>

                                    {isGucciCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Gucci Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> nikeCatalog(e)}>

                                    {isNikeCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Nike Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> adidasCatalog(e)}>

                                    {isAdidasCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Adidas Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item  md={4}>
                    <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                        <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                            <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> bershkaCatalog(e)}>

                                {isBershkaCatalog ? (
                                    <>
                                        <Spinner
                                            animation="border"
                                            size="sm"
                                            variant="light"
                                        />
                                    </>
                                ) : (
                                    'Start Bershka Catalog'
                                )}

                            </Button>

                        </Box>
                    </Paper>
                </Grid>
                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> lululemonCatalog(e)}>

                                    {isLululemonCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Lululemon Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> mangoCatalog(e)}>

                                    {isMangoCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Mango Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> uniqloCatalog(e)}>

                                    {isUniqloCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Uniqlo Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    {/* <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> foreverCatalog(e)}>

                                    {isForeverCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Forever Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid> */}
                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> oldNavyCatalog(e)}>

                                    {isOldNavyCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Old Navy Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> levisCatalog(e)}>

                                    {isLevisCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        "Start Levi's Catalog"
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> hmCatalog(e)}>

                                    {isHmCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start H&m Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    {/* <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> asosCatalog(e)}>

                                    {isAsosCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Asos Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid> */}
                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> lacosteCatalog(e)}>

                                    {isLacosteCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Lacoste Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> givenchyCatalog(e)}>

                                    {isGivenchyCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Givenchy Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> massimoCatalog(e)}>

                                    {isMassimoCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Massimo Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    {/* <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> superDryCatalog(e)}>

                                    {isSuperDryCatalog ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Super dry Catalog'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid> */}
                    <Grid item  md={4}>
                    <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                        <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                            <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> zaraCatalog(e)}>

                                {isZaraCatalog ? (
                                    <>
                                        <Spinner
                                            animation="border"
                                            size="sm"
                                            variant="light"
                                        />
                                    </>
                                ) : (
                                    'Start Zara Catalog'
                                )}

                            </Button>

                        </Box>
                    </Paper>
                </Grid>
                    <Grid item  md={4}>
                    <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                        <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                            <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> aeCatalog(e)}>

                                {isAeCatalog ? (
                                    <>
                                        <Spinner
                                            animation="border"
                                            size="sm"
                                            variant="light"
                                        />
                                    </>
                                ) : (
                                    'Start Ae Catalog'
                                )}

                            </Button>

                        </Box>
                    </Paper>
                </Grid>


                <Grid item  md={4}>
                    <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                        <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                            <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> mollyCatalog(e)}>

                                {isMollyCatalog ? (
                                    <>
                                        <Spinner
                                            animation="border"
                                            size="sm"
                                            variant="dark"
                                        />
                                    </>
                                ) : (
                                    'molly catalog'
                                )}

                            </Button>

                        </Box>
                    </Paper>
                </Grid>


                <Grid item  md={4}>
                    <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                        <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                            <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> antropologieCatalog(e)}>

                                {isantropologieCatalog ? (
                                    <>
                                        <Spinner
                                            animation="border"
                                            size="sm"
                                            variant="dark"
                                        />
                                    </>
                                ) : (
                                    'antropologie catalog'
                                )}

                            </Button>

                        </Box>
                    </Paper>
                </Grid>


                <Grid item  md={4}>
                    <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                        <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                            <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> skimsCatalog(e)}>

                                {isskimsCatalog ? (
                                    <>
                                        <Spinner
                                            animation="border"
                                            size="sm"
                                            variant="dark"
                                        />
                                    </>
                                ) : (
                                    'skims catalog'
                                )}

                            </Button>

                        </Box>
                    </Paper>
                </Grid>


                <Grid item  md={4}>
                    <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                        <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                            <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> pacsunCatalog(e)}>

                                {ispacsunCatalog ? (
                                    <>
                                        <Spinner
                                            animation="border"
                                            size="sm"
                                            variant="dark"
                                        />
                                    </>
                                ) : (
                                    'pacsun catalog'
                                )}

                            </Button>

                        </Box>
                    </Paper>
                </Grid>


                <Grid item  md={4}>
                    <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                        <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                            <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> abercrombieCatalog(e)}>

                                {issabercrombieCatalog ? (
                                    <>
                                        <Spinner
                                            animation="border"
                                            size="sm"
                                            variant="dark"
                                        />
                                    </>
                                ) : (
                                    'AbercrombieCatalog Catalog'
                                )}

                            </Button>

                        </Box>
                    </Paper>
                </Grid>





                </Grid>
            </Box>

    </div>
)
}
export default Actions;