import React, { useState, useEffect,useRef } from 'react';
import style from './orderRefund.module.css';
import {getOrderwithLiveRequest, postWithLiveRequest} from "../../Services/networkRequests";
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';
import 'datatables.net-bs5'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from "@mui/material/SvgIcon/SvgIcon";
import ListItemAvatar from "@mui/material/ListItemAvatar/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import {blue} from "@mui/material/colors";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import DatePicker from "react-datepicker";
import Collapse from "@mui/material/Collapse/Collapse";
const OrderRefund = () =>{
    const [showData,setShowData]=useState(false);
    const [showLoader, setShowLoader] = useState(false)
    const [data, setData] = useState([]);
    const dataTableRef = useRef(null);
    const [show, setShow] = useState(false);
    const [refundId, setRefundId] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const status = ['Received', 'Confirmed', 'Shipped from User', 'Refunded', 'Rejected'];
    useEffect(() => {

        fetchData();

    }, []);

    useEffect(() => {
        // Initialize DataTable when the component mounts
        if (showData) {
            $(dataTableRef.current).DataTable({
                aaSorting: [], // Empty array disables initial sorting
                lengthMenu: [10, 20, 25],
                pageLength: 10, // Set the initial page length to 5 if you want
            });        }
    }, [showData]);

    const fetchData = async () => {
        setShowLoader(true);
        const result = await getOrderwithLiveRequest('/all-refund-orders');
        if(result){
            setData(result);
            setShowData(true);
            setShowLoader(false);
        }else{
            setShowData(false);
            setShowLoader(true);
        }

    };

    const handleListItemClick = async (value) => {

        let params ={
            refundId: refundId,
            status: value.toLocaleLowerCase(),
        }
        const result = await postWithLiveRequest('update-refund-order', params);
        if (result.data) {
            toast(result.data.message);
            setShow(false);
            setShowData(false);
            fetchData();
        } else {
            toast('Something went wrong');

        }
    };

    const editItem = async (id) => {
        setShow(true)
        setRefundId(id);

    };


    return(
        <div>
            <section className={`${style.status_listing_section}`}>
                <ToastContainer />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">

                            <div className={`${style.status_listing_card} card border-secondary mb-3`}>
                                <div className={`${style.status_listing_card_header} card-header `}>
                                    <h1>Order Refund</h1>
                                </div>
                                <div className="card-body">
                                    <div className={`${style.status_listing}`}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {showData &&
                                                <div className="table-responsive">
                                                    <table className="table table-hover table-bordered"
                                                           ref={dataTableRef}
                                                    >
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">Refund ID</th>
                                                            <th scope="col">User ID</th>
                                                            <th scope="col">User Email</th>
                                                            <th scope="col">User Phone Number</th>
                                                            <th scope="col">Variation ID</th>
                                                            <th scope="col">Delivery Date</th>
                                                            <th scope="col">Main Order ID</th>
                                                            <th scope="col">Sub Order ID</th>
                                                            <th scope="col">Total Order</th>
                                                            <th scope="col">Refund Value</th>
                                                            <th scope="col">Refund Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {data !== null && data.length ? (
                                                            data.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.id ? item.id: 'N/A'}</td>
                                                                    <td>{item.user ? item.user: 'N/A'}</td>
                                                                    <td>{item.userEmail ? item.userEmail: 'N/A'}</td>
                                                                    <td>{item.userPhoneNumber ? item.userPhoneNumber: 'N/A'}</td>
                                                                    <td>
                                                                        {item.variations && item.variations.length
                                                                            ? item.variations.map((variation, index) => (
                                                                                <React.Fragment key={index}>
                                                                                    <span>{variation}</span><br/>
                                                                                </React.Fragment>
                                                                            ))
                                                                            : 'N/A'}
                                                                    </td>
                                                                    <td>{item.deliveryDate ? item.deliveryDate: 'N/A'}</td>
                                                                    <td>{item.orderLimited ? item.orderLimited: 'N/A'}</td>
                                                                    <td>
                                                                        {item.orders && item.orders.length
                                                                            ? item.orders.map((order, index) => (
                                                                                <span key={index}> {order}</span>
                                                                            ))
                                                                            : 'N/A'}
                                                                    </td>
                                                                    <td>{item.TotalValueOfOrder ? '$'+item.TotalValueOfOrder: 'N/A'}</td>
                                                                    <td>{item.refundAmount ? '$'+item.refundAmount: 'N/A'}</td>
                                                                    <td>{item.status ? item.status: 'N/A'}</td>
                                                                    <td>
                                                                        <span className="badge text-bg-success" style={{cursor:"pointer"}} onClick={()=>{editItem(item.id )}}>Edit</span> &nbsp;
                                                                        <span className="badge text-bg-danger" style={{cursor:"pointer"}}>Delete</span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <></>
                                                        )}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                }
                                                {showLoader &&
                                                <>
                                                    <div className="text-center pt-5">
                                                        <Spinner animation="border" role="status" size="lg" style={{
                                                            height: '5rem',
                                                            width: '5rem'
                                                        }}>
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                </>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={show} onHide={handleClose} backdrop="static" centered>
                        <Modal.Body>
                            <div className="text-end" onClick={handleClose} style={{cursor: "pointer"}}>
                                <ClearIcon/>
                            </div>
                            <Box>


                                    <List sx={{pt: 0}}>
                                        {status.map((status) => (
                                            <ListItem disableGutters key={status}>
                                                <ListItemButton onClick={() => handleListItemClick(status)}>
                                                    <ListItemAvatar>
                                                        <Avatar sx={{bgcolor: blue[100], color: blue[600]}}>
                                                            <AddIcon/>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={status}/>
                                                </ListItemButton>
                                            </ListItem>
                                        ))}

                                    </List>


                            </Box>
                        </Modal.Body>

                    </Modal>
                </div>
            </section>
        </div>
    )
}
export default OrderRefund;