import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Spinner from 'react-bootstrap/Spinner';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import Tables from '../../Components/Tables'
import { deleteRequest, getRequest, getOrderwithLiveRequest } from '../../Services/networkRequests'
import { useNavigate } from 'react-router-dom';
import { getAddress, getBrandNames } from '../../Services/Utility';
import PopUp from '../../Components/PopUp';
import ExpandableTable from '../../Components/ExpandableTable';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Pagination from '@mui/material/Pagination';

export const ListingOrders = () => {
    const navigate = useNavigate();
    const [cars, setCars] = useState([])
    const [errorOrders, setErrorOrders] = useState([])
    const [realOrders, setRealOrders] = useState([])
    const [flagOrders, setFlagOrders] = useState([])
    const [partialDeliveredOrders, setPartialDeliveredOrders] = useState([])
    const [row, setRow] = useState({})
    const [showLoader, setShowLoader] = useState(true);
    const [showData,setShowData]=useState(false);
    const [openError, setOpenError] = useState(false);
    const [openFlagError, setOpenFlagError] = useState(false);
    const [openDeliveredError, setOpenDeliveredError] = useState(false);
    const [openPopUp, setOpenPopUp] = useState(false);
    const titleStyle = { padding: '20px', width: '100%', margin: "0" }
    const [page, setPage] = React.useState(1);
    const [pageCount, setPageCount] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        async function fetchOrders() {
            setShowLoader(true);
            setShowData(false);
            const _orders = await getOrderwithLiveRequest(`all-complete-orders/${page}`)
            const onlyOrder = _orders.ordersLimited.filter(item => ['received', 'shipped','confirmed', 'delivered'].includes(item.status))
            const errorOrder = _orders.ordersLimited.filter(item => !['received', 'shipped', 'confirmed', 'delivered'].includes(item.status))
            const totalPages =_orders.totalPages;
            const flagOrder = _orders.ordersLimited.filter(item => ![false].includes(item.deliveryDatePassed))
            // const deliverOrder = _orders.filter(item => ![false].includes(item.delivered))
            // console.log(flagOrder);
            setCars(onlyOrder)
            setRealOrders(onlyOrder)
            setPageCount(totalPages)
            setFlagOrders(flagOrder)
            // setPartialDeliveredOrders(deliverOrder)
            setErrorOrders(errorOrder)
            setShowLoader(false);
            setShowData(true);
        }
        fetchOrders()
    }, [page])
    const refreshOrders = async () => {
        // const _orders = await getRequest('orders');
        // const onlyOrder = _orders.filter(item => ['received', 'shiped', 'confirmed', 'delivered'].includes(item.status))
        // const errorOrder = _orders.filter(item => !['received', 'shiped', 'confirmed', 'delivered'].includes(item.status))
        // setCars(onlyOrder)
        // setRealOrders(onlyOrder)
        // setErrorOrders(errorOrder)
        setShowLoader(true);
        setShowData(false);
        const _orders = await getOrderwithLiveRequest(`all-complete-orders/${page}`)
        const onlyOrder = _orders.ordersLimited.filter(item => ['received', 'shipped', 'confirmed', 'delivered'].includes(item.status))
        const errorOrder = _orders.ordersLimited.filter(item => !['received', 'shipped', 'confirmed', 'delivered'].includes(item.status))
        const totalPages =_orders.totalPages;
        const flagOrder = _orders.ordersLimited.filter(item => ![false].includes(item.deliveryDatePassed))
        // const deliverOrder = _orders.filter(item => ![false].includes(item.delivered))
        // console.log(flagOrder);
        setCars(onlyOrder)
        setRealOrders(onlyOrder)
        setPageCount(totalPages)
        setFlagOrders(flagOrder)
        // setPartialDeliveredOrders(deliverOrder)
        setErrorOrders(errorOrder)
        setShowLoader(false);
        setShowData(true);
    };
    // data to pass to table component
    const handlePreview = (row) => {
        console.log('row', row)
        setRow(row)
        setOpenPopUp(true)
        // return navigate("/add-car/" + arg.id, { replace: true })
    }
    const handleDelete = async (arg) => {
        try {
            await deleteRequest(`/car/${arg.id}`);
            refreshOrders();
        } catch (error) {
                    throw error;
        }
    };
    const handleAddNew = (arg) => console.log(arg)
    const filterError = () => {
        if (openError) {
            setCars(realOrders)
            setOpenError(false)
        } else {
            setCars(errorOrders)
            setOpenError(true)
        }
    }
    const filterFlag = () => {
        if (openFlagError) {
            setCars(realOrders);
            setOpenFlagError(false)
        } else {
            setCars(flagOrders)
            setOpenFlagError(true)
        }
    }
    const filterDeliverFlag = () => {
        if (openDeliveredError) {
            setCars(realOrders)
            setOpenDeliveredError(false)
        } else {
            setCars(partialDeliveredOrders)
            setOpenDeliveredError(true)
        }
    }
    const columns = [
        { field: 'uuid', headerName: 'uuid', width: 200 },
        {
            field: 'brands', headerName: 'Brands', width: 200,
            renderCell: (params) => {
                const names = getBrandNames(params.row.brands)
                return (
                    <div>{names}</div>
                )
            }
        },
        { field: 'totalAmount', headerName: 'Amount', width: 200 },
        { field: 'status', headerName: 'Status', width: 200 },
        { field: 'stripeStatus', headerName: 'stripeStatus', width: 200 },
        { field: 'createdAt', headerName: 'Date', width: 200, },
        // { field: 'trackingId', headerName: 'Tracking ID', width: 200, },
        // { field: 'deliveryDate', headerName: 'Delivery Date', width: 200, },
        // {
        //     field: 'address', headerName: 'Address', width: 200,
        //     renderCell: (params) => {
        //         const address = getAddress(params)
        //         return(
        //             <div>{address}</div>
        //         )
        //     }
        // },
        {
            field: "actions",
            flex: 1,
            minWidth: 100,
            maxWidth: 200,
            align: 'right',
            headerName: "Actions",
            headerClassName: "grid-header-custom",
            headerAlign: "center",
            editable: false,
            renderCell: (params) => {
                return (
                    <Box display={"flex"} justifyContent="space-around" width={"100%"}>
                        {params.status ==='shipped'? (
                            <>
                                <Tooltip title="Edit" placement="left-start">
                                    <IconButton
                                        // onClick={() => handlePreview(params.row)}
                                        onClick={() => handlePreview(params)}
                                        sx={{ boxShadow: "0px 0px 10px rgba(37, 133, 202, 0.2)" }}
                                    >
                                        <DriveFileRenameOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        ) : (
                           <></>
                        )}
                        
                        <Tooltip title="Delete" placement="right-start">
                            <IconButton
                                // onClick={() => handleDelete(params)}
                                sx={{ boxShadow: "0px 0px 10px rgba(37, 133, 202, 0.2)" }}
                            >
                                <DeleteOutlineIcon color="error" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                );
            },
        },
    ];

    return (
        <div>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} maxWidth={'400px'}>
                    <h3 style={titleStyle}>Orders</h3>
                    <Tooltip title="Filter Error Orders" placement="left-start">
                        <IconButton
                            onClick={() => filterError()}
                            sx={{ boxShadow: "0px 0px 10px rgba(37, 133, 202, 0.2)" }}
                        >
                            {openError ? <FormatListBulletedIcon /> : <ErrorOutlineIcon />}
                        </IconButton>
                    </Tooltip>
                   
                    <Tooltip title="Flag Orders" placement="left-start">
                        <IconButton
                            onClick={() => filterFlag()}
                            sx={{ boxShadow: "0px 0px 10px rgba(37, 133, 202, 0.2)" }}
                            className="ms-5"
                        >
                            {openFlagError ? <FormatListBulletedIcon /> : <ErrorOutlineIcon />}
                        </IconButton>
                    </Tooltip>
                    {/*<Tooltip title="Partialy Delivered Orders" placement="left-start">*/}
                    {/*    <IconButton*/}
                    {/*        onClick={() => filterDeliverFlag()}*/}
                    {/*        sx={{ boxShadow: "0px 0px 10px rgba(37, 133, 202, 0.2)" }}*/}
                    {/*        className="ms-5"*/}
                    {/*    >*/}
                    {/*        {openDeliveredError ? <FormatListBulletedIcon /> : <ErrorOutlineIcon />}*/}
                    {/*    </IconButton>*/}
                    {/*</Tooltip>*/}
                </Box>
                {/* <Tables tableTitle={'Orders Listing'} tableData={cars} cols={columns} /> */}
                {showData &&
                <>
                    <ExpandableTable tableData={cars} cols={columns} refreshOrders={refreshOrders}/>
                </>
                }
                {showLoader && (
                    <>
                        <div className="text-center pt-5 pb-5">
                            <Spinner
                                animation="border"
                                role="status"
                                size="lg"
                                style={{
                                    height: '5rem',
                                    width: '5rem',
                                }}
                            >
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    </>
                )}
                <div className="float-end mt-2 p-3">
                <Pagination count={pageCount} page={page} onChange={handleChange} variant="outlined" shape="rounded"/>
                </div>
                <PopUp openPopUp={openPopUp} setOpenPopUp={setOpenPopUp} row={row} refreshOrders={refreshOrders} />
            </Paper>
        </div>
    )



}