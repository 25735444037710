import React, { useState, useEffect, useRef } from 'react';
import style from './userSection.module.css';
import {  postRequest, getBetaRequest } from "../../Services/networkRequests";
import Spinner from 'react-bootstrap/Spinner';
import $ from 'jquery';
import 'datatables.net-bs5'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import { postWithLiveRequest, getOrderwithLiveRequest } from '../../Services/networkRequests';


const UserSection = () => {

    const [isEnabled, setIsEnabled] = useState(true); 
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [records, setRecords] = useState([]);
    const [recordsFemale, setrecordsFemale] = useState([]);

    const [recordBuyFromBrand, setrecordBuyFromBrand] = useState([]);

    const [editingIndex, setEditingIndex] = useState(null);
    const [femaleeditingIndex, setfemaleeditingIndex] = useState(null);

    const [editedName, setEditedName] = useState('');
    const [editedFemaleName, seteditedFemaleName] = useState('');


    const [isEditing, setIsEditing] = useState(false);
    const [isFemaleEditing, setisFemaleEditing] = useState(false);


    const [tagId, setTagId] = useState(null)
    const wrapperRef = useRef(null);


    const [data, setData] = useState([]);
    const [showData, setShowData] = useState(false);
    const [showLoader, setShowLoader] = useState(true)
    const [showNotifyLoader, setShowNotifyLoader] = useState(false)
    const dataTableRef = useRef(null);
    const [orderData, setOrderData] = useState([]);
    const [showOrderData, setShowOrderData] = useState(false);
    const [showOrderLoader, setShowOrderLoader] = useState(true)
    const dataOrderTableRef = useRef(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [dateRange2, setDateRange2] = useState([null, null]);
    const [userStartDate, setUserStartDate] = useState(null);
    const [userEndDate, setUserEndDate] = useState(null);
    const [orderStartDate, setOrderStartDate] = useState(null);
    const [count, setCount] = useState('');
    const [orderEndDate, setOrderEndDate
    ] = useState(null);
    const [startDate, endDate] = dateRange;
    const [startDate2, endDate2] = dateRange2;
    useEffect(() => {
        fetchData();
        fetchOrderData();
    }, []);
    useEffect(() => {
        // Initialize DataTable when the component mounts
        if (showData) {
            $(dataTableRef.current).DataTable({
                aaSorting: [], // Empty array disables initial sorting
                lengthMenu: [5, 10, 25],
                pageLength: 5, // Set the initial page length to 5 if you want
            });
        }
    }, [showData]);
    useEffect(() => {
        // Initialize DataTable when the component mounts
        if (showOrderData) {
            $(dataOrderTableRef.current).DataTable({
                aaSorting: [], // Empty array disables initial sorting
                lengthMenu: [5, 10, 25],
                pageLength: 5, // Set the initial page length to 5 if you want
            });
        }
    }, [showOrderData]);
    const fetchData = async () => {
        setShowLoader(true);
        const result = await postRequest('/complete-user', {});
        if (result.data) {
            setData(result.data.data);
            setCount(result.data.count);
        } else {
            toast('Something went wrong');
        }
        setShowLoader(false);
        setShowData(true);

    };
    const submitFilters = async () => {
        setShowData(false);
        setShowLoader(true);
        const result = await postRequest('/complete-user', {});
        if (result.data) {
            setData(result.data.data)
            setCount(result.data.count);
        } else {
            toast('Something went wrong');
        }
        setShowLoader(false);
        setShowData(true);
    };
    const submitRangeFilters = async () => {
        setShowData(false);
        setShowLoader(true);
        let params = {
            "startDate": userStartDate,
            "endDate": userEndDate,
        }
        const result = await postRequest('/complete-user', params);

        if (result.data) {
            setData(result.data.data)
            setCount(result.data.count);
        } else {
            toast('Something went wrong');
        }
        setShowLoader(false);
        setShowData(true);
    };
    const fetchOrderData = async () => {
        setShowOrderLoader(true);
        const result = await postRequest('/complete-order-analysis', {});
        if (result.data) {
            setOrderData(result.data.data);
        } else {
            toast('Something went wrong');
        }
        setShowOrderLoader(false);
        setShowOrderData(true);

    };
    const submitOrderFilters = async () => {
        setShowOrderData(false);
        setShowOrderLoader(true);
        const result = await postRequest('/complete-order-analysis', {});
        if (result.data) {
            setOrderData(result.data.data);
        } else {
            toast('Something went wrong');
        }
        setShowOrderLoader(false);
        setShowOrderData(true);
    };
    const submitOrderRangeFilters = async () => {
        setShowOrderData(false);
        setShowOrderLoader(true);
        let params = {
            "startDate": orderStartDate,
            "endDate": orderEndDate,
        }
        const result = await postRequest('/complete-order-analysis', params);
        if (result.data) {
            setOrderData(result.data.data);
        } else {
            toast('Something went wrong');
        }
        setShowOrderLoader(false);
        setShowOrderData(true);
    };

    const changeDatepicker = (update) => {
        if (update[0] && update[1]) {
            const startDateFormatted = format(update[0], 'yyyy-MM-dd HH:mm:ss');
            const endDateFormatted = format(update[1], 'yyyy-MM-dd HH:mm:ss');
            setUserStartDate(startDateFormatted);
            setUserEndDate(endDateFormatted);

        }
        setDateRange(update);

    }
    const changeDatepicker2 = (update) => {
        if (update[0] && update[1]) {
            const startDateFormatted = format(update[0], 'yyyy-MM-dd HH:mm:ss');
            const endDateFormatted = format(update[1], 'yyyy-MM-dd HH:mm:ss');
            setOrderStartDate(startDateFormatted);
            setOrderEndDate(endDateFormatted);
        }
        setDateRange2(update);
    }


    const sendNotification = async () => {
        try {
            setShowNotifyLoader(true);
            // Make an HTTP POST request to your backend API endpoint
            const response = await postWithLiveRequest('send-notification-to-all-users', {
                title: title,
                message: message
            });
            console.log("Notification sent successfully:", response.data);
            // Optionally, show a success message to the user
            setTitle("");
            setMessage("");
            if (response.status === 200) {
                toast('Notification sent to all users successfully');

            } else {
                toast('Something went wrong');
            }
            setShowNotifyLoader(false);
        } catch (error) {
            console.error("Failed to send notification:", error);
            // Optionally, show an error message to the user
        }
    };


    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await getOrderwithLiveRequest('get-all-tags');
                console.log('response' , response)
                setRecords(response.Mentags);
                setrecordsFemale(response.FemaleTags) // Assuming response.data contains the array of tags
            } catch (error) {
                console.error('Error fetching tags:', error);
            }
        };

        fetchTags();
    }, []);

    useEffect(() => {
        const fetchBuyFromBrand = async () => {
            try {
                const response = await getOrderwithLiveRequest('get-buy-from-brand');
                setrecordBuyFromBrand(response.BuyFromBrand); 
            } catch (error) {
                console.error('Error fetching tags:', error);
            }
        };

        fetchBuyFromBrand();
    }, []);

    const handleEditClick = (index, name, id) => {
        setEditingIndex(index);
        setEditedName(name);
        setIsEditing(true);
        setTagId(id);
    };

    const handleFemaleEditClick = (index, name, id) => {
        setfemaleeditingIndex(index);
        seteditedFemaleName(name);
        setisFemaleEditing(true);
        setTagId(id);
    };

    const handleNameChange = (event) => {
        setEditedName(event.target.value);
    };

    const handleFemaleNameChange = (event) => {
        seteditedFemaleName(event.target.value);
    };

    const handleResetClick = () => {
        setEditedName('');
        seteditedFemaleName('')
        setEditingIndex(null);
        setfemaleeditingIndex(null)
        setIsEditing(false);
        setisFemaleEditing(false)
    };

    const handleClickOutside = (event) => {
        if (!wrapperRef.current.contains(event.target)) {
            setEditingIndex(null);
            setfemaleeditingIndex(null)
            setIsEditing(false);
            setisFemaleEditing(false)
        }
    };

    const saveTag = async (event, id) => {
        console.log('ID:', id);
        
        try {
            const updateTagName = await postWithLiveRequest('update-tag', {
                tagID: id,
                name: editedName
            })

            console.log("Name updated successfully:", updateTagName);

            if (updateTagName.status === 200) {

                setRecords(prevRecords => {
                    const updatedRecords = [...prevRecords];
                    const index = updatedRecords.findIndex(record => record.id === id);
                    if (index !== -1) {
                        updatedRecords[index].name = editedName;
                    }
                    return updatedRecords;
                });
                toast('Tag updated successfully');

            } else {
                toast('Something went wrong');
            }
            setEditedName('');
            setEditingIndex(null);
            setIsEditing(false);
        } catch (error) {
            console.error("Failed to update tags:", error);

        }

    }

    const saveFemaleTag = async (event, id) => {
        console.log('ID:', id);
        console.log('edited name', editedFemaleName)
        try {
            const updateTagName = await postWithLiveRequest('update-tag', {
                tagID: id,
                name: editedFemaleName
            })

            console.log("Name updated successfully:", updateTagName);

            if (updateTagName.status === 200) {

                setrecordsFemale(prevRecords => {
                    const updatedRecords = [...prevRecords];
                    const index = updatedRecords.findIndex(record => record.id === id);
                    if (index !== -1) {
                        updatedRecords[index].name = editedFemaleName;
                    }
                    return updatedRecords;
                });
                toast('Tag updated successfully');

            } else {
                toast('Something went wrong');
            }
            seteditedFemaleName('');
            setfemaleeditingIndex(null);
            setisFemaleEditing(false);
        } catch (error) {
            console.error("Failed to update tags:", error);

        }

    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleTagStatusChange = async (id) => {
        try {
            const updateTagStatus = await postWithLiveRequest('update-status-tag', {
                tagID: id
            })

            console.log("status updated successfully:", updateTagStatus);

            if (updateTagStatus.status === 200) {


                toast('Tag status updated successfully');
                 // Update the status in the local state
            setRecords(prevRecords =>
                prevRecords.map(record =>
                    record.id === id ? { ...record, status: !record.status } : record
                )
            );

            } else {
                toast('Something went wrong');
            }
        } catch (error) {
            console.error("Failed to update tags:", error);
        }
    }

    const handleFemaleTagStatusChange = async (id) => {
        try {
            const updateTagStatus = await postWithLiveRequest('update-status-tag', {
                tagID: id
            })

            console.log("status updated successfully:", updateTagStatus);

            if (updateTagStatus.status === 200) {


                toast('Tag status updated successfully');
                 // Update the status in the local state
            setrecordsFemale(prevRecords =>
                prevRecords.map(record =>
                    record.id === id ? { ...record, status: !record.status } : record
                )
            );

            } else {
                toast('Something went wrong');
            }
        } catch (error) {
            console.error("Failed to update tags:", error);
        }
    }

    const handleBuyFromBrandStatusChange = async (id) => {
        try {
            const updateBuyFromBrandStatus = await postWithLiveRequest('update-buy-from-brand-status', {
                ID: id
            })

            console.log("status updated successfully:", updateBuyFromBrandStatus);

            if (updateBuyFromBrandStatus.status === 200) {


                 // Update the status in the local state
                 setrecordBuyFromBrand(prevRecords =>
                prevRecords.map(record =>
                    record.id === id ? { ...record, status: !record.status } : record
                )
            );
            toast('Tag status updated successfully');


            } else {
                toast('Something went wrong');
            }
        } catch (error) {
            console.error("Failed to update tags:", error);
        }
    }


    //toggle style quiz
    const handleToggleChange = async (e) => {
        const newStatus = e.target.checked;
    
        setIsEnabled(newStatus);
    
        try {
          const response = await getBetaRequest('update-buy-stylequiz-status');
    
          if (response.status === 200) {
            console.log("Status updated successfully:",);
          } else {
            console.error("Failed to update status:", data.message);
          }
        } catch (error) {
          console.error("Error calling the API:", error);
        }
      };


    return (
        <div>
            <section className={`${style.status_listing_section}`}>
                <ToastContainer />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className={`${style.status_listing_card} card border-secondary mb-3`}>
                                <div className={`${style.status_listing_card_header} card-header `}>
                                    <h1>User Section</h1>
                                </div>
                                <div className="card-body">
                                    <div className={`${style.status_listing}`}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="text-start mb-2">
                                                            <button className={`${style.cstm_btn} btn btn-secondary me-2`} type="button"
                                                                onClick={(e) => { submitFilters() }}>
                                                                Show All Data
                                                            </button>

                                                            {/*<div className="dropdown">*/}
                                                            {/*    <button className={`${style.cstm_btn} btn btn-secondary dropdown-toggle me-2`}type="button"*/}
                                                            {/*            data-bs-toggle="dropdown" aria-expanded="false">*/}
                                                            {/*        {showingFilter}*/}
                                                            {/*    </button>*/}
                                                            {/*    <ul className="dropdown-menu">*/}
                                                            {/*        <li><a className="dropdown-item" onClick={(e) => { setShowingFilter('daily') }}>Daily</a></li>*/}
                                                            {/*        <li><a className="dropdown-item" onClick={(e) => { setShowingFilter('weekly') }}>Weekly</a></li>*/}
                                                            {/*        <li><a className="dropdown-item" onClick={(e) => { setShowingFilter('monthly') }}>Monthly</a>*/}
                                                            {/*        </li>*/}
                                                            {/*    </ul>*/}
                                                            {/*</div>*/}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="user-date">
                                                            <label>Slelect Date &nbsp;</label>
                                                            <DatePicker
                                                                selectsRange={true}
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                className="cstm-user-datepicker"
                                                                onChange={(update) => {
                                                                    changeDatepicker(update);
                                                                }}
                                                                isClearable={true}
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="text-start mb-2">
                                                            <button className={`${style.cstm_btn} btn btn-secondary me-2`} type="button"
                                                                onClick={(e) => { submitRangeFilters() }}>
                                                                Execute
                                                            </button>

                                                        </div>
                                                    </div>

                                                </div>
                                                <hr />

                                                {showData && (
                                                    <>
                                                        <p><strong>User Count</strong> : {count ? count : 'N/A'}</p>
                                                        <div className="table-responsive">
                                                            <table
                                                                ref={dataTableRef}
                                                                className="table table-hover table-bordered status-table"
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">#</th>
                                                                        <th scope="col">Email</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {data.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item.email}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </>
                                                )}

                                                {showLoader && (
                                                    <>
                                                        <div className="text-center pt-5">
                                                            <Spinner
                                                                animation="border"
                                                                role="status"
                                                                size="lg"
                                                                style={{
                                                                    height: '5rem',
                                                                    width: '5rem',
                                                                }}
                                                            >
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="col-md-6">
                            <div className={`${style.status_listing_card} card border-secondary mb-3`}>
                                <div className={`${style.status_listing_card_header} card-header `}>
                                    <h1>Order Analysis</h1>
                                </div>
                                <div className="card-body">
                                    <div className={`${style.status_listing}`}>
                                        <div className="row">
                                            <div className="col-md-12">

                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="text-start mb-2">
                                                            <button className={`${style.cstm_btn} btn btn-secondary me-2`} type="button"
                                                                onClick={(e) => { submitOrderFilters() }}>
                                                                Show All Data
                                                            </button>


                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="user-date">
                                                            <label>Slelect Date &nbsp;</label>
                                                            <DatePicker
                                                                selectsRange={true}
                                                                startDate={startDate2}
                                                                endDate={endDate2}
                                                                className="cstm-user-datepicker"
                                                                onChange={(update) => {
                                                                    changeDatepicker2(update);
                                                                }}
                                                                isClearable={true}
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="text-start mb-2">
                                                            <button className={`${style.cstm_btn} btn btn-secondary me-2`} type="button"
                                                                onClick={(e) => { submitOrderRangeFilters() }}>
                                                                Execute
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                {showOrderData && (
                                                    <>

                                                        <div className="table-responsive">
                                                            <table
                                                                ref={dataOrderTableRef}
                                                                className="table table-hover table-bordered status-table"
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Average Order Value</th>
                                                                        <th scope="col">Total Order Value</th>
                                                                        <th scope="col">Number of Orders</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {orderData !== null && orderData.length ? (
                                                                        <tr key={1}>
                                                                            <td>
                                                                                {orderData[0].average_order_value
                                                                                    ? '$' + parseFloat(orderData[0].average_order_value).toFixed(2)
                                                                                    : 'N/A'}
                                                                            </td>
                                                                            <td>
                                                                                {orderData[0].total_orders_amount
                                                                                    ? '$' + parseFloat(orderData[0].total_orders_amount).toFixed(2)
                                                                                    : 'N/A'}
                                                                            </td>
                                                                            <td>
                                                                                {orderData[0].num_orders ? orderData[0].num_orders : 'N/A'}
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </>
                                                )}

                                                {showOrderLoader && (
                                                    <>
                                                        <div className="text-center pt-5">
                                                            <Spinner
                                                                animation="border"
                                                                role="status"
                                                                size="lg"
                                                                style={{
                                                                    height: '5rem',
                                                                    width: '5rem',
                                                                }}
                                                            >
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="col-md-6">
                            <div className={`${style.status_listing_card} card border-secondary mb-3`}>
                                <div className={`${style.status_listing_card_header} card-header `}>
                                    <h1>Male Tags</h1>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Actions</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {records.map((record, index) => (
                                                <tr key={record.id}>
                                                    <th scope="row">{index + 1}</th>

                                                    <td>
                                                        {editingIndex === index && isEditing ? (
                                                            <input
                                                                type="text"
                                                                value={editedName}
                                                                onChange={handleNameChange}
                                                                className="form-control"
                                                            />
                                                        ) : (
                                                            record.name
                                                        )}
                                                    </td>

                                                    <td>
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={record.status}
                                                                onChange={() => handleTagStatusChange(record.id)}
                                                            />
                                                        </div>
                                                    </td>



                                                    <td>
                                                        {editingIndex === index && isEditing ? (
                                                            <div>
                                                                <button className="btn btn-success btn-sm" onClick={(el) => saveTag(el, tagId)}>Save</button>
                                                                <button className="btn btn-danger btn-sm ml-2" onClick={handleResetClick}>Reset</button>
                                                            </div>
                                                        ) : (
                                                            <button
                                                                className="btn btn-primary btn-sm mr-2"
                                                                onClick={() => handleEditClick(index, record.name, record.id)}
                                                            >
                                                                Edit
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>







                        <div className="col-md-6">
                            <div className={`${style.status_listing_card} card border-secondary mb-3`}>
                                <div className={`${style.status_listing_card_header} card-header `}>
                                    <h1>Female Tags</h1>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Actions</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {recordsFemale.map((record, index) => (
                                                <tr key={record.id}>
                                                    <th scope="row">{index + 1}</th>

                                                    <td>
                                                        {femaleeditingIndex === index && isFemaleEditing ? (
                                                            <input
                                                                type="text"
                                                                value={editedFemaleName}
                                                                onChange={handleFemaleNameChange}
                                                                className="form-control"
                                                            />
                                                        ) : (
                                                            record.name
                                                        )}
                                                    </td>

                                                    <td>
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={record.status}
                                                                onChange={() => handleFemaleTagStatusChange(record.id)}
                                                            />
                                                        </div>
                                                    </td>



                                                    <td>
                                                        {femaleeditingIndex === index && isFemaleEditing ? (
                                                            <div>
                                                                <button className="btn btn-success btn-sm" onClick={(el) => saveFemaleTag(el, tagId)}>Save</button>
                                                                <button className="btn btn-danger btn-sm ml-2" onClick={handleResetClick}>Reset</button>
                                                            </div>
                                                        ) : (
                                                            <button
                                                                className="btn btn-primary btn-sm mr-2"
                                                                onClick={() => handleFemaleEditClick(index, record.name, record.id)}
                                                            >
                                                                Edit
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>



















                        <div className="col-md-6">
                            <div className={`${style.status_listing_card} card border-secondary mb-3`}>
                                <div className={`${style.status_listing_card_header} card-header `}>
                                    <h1>Buy From Brand</h1>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Country</th>
                                                <th scope="col">Status</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                                {console.log('buy from brand state' , recordBuyFromBrand)}
                                            {recordBuyFromBrand.map((record, index) => (
                                                <tr key={record.id}>
                                                    <th scope="row">{index + 1}</th>

                                                    <td>
                                                       {record ? record.country : 'null'}
                                                    </td>

                                                    <td>
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={record.status}
                                                                onChange={() => handleBuyFromBrandStatusChange(record.id)}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>




                        <div className="col-md-6">
                            <div className={`${style.status_listing_card} card border-secondary mb-3`}>
                                <div className={`${style.status_listing_card_header} card-header `}>
                                    <h1>Notifiction for all Users</h1>
                                </div>
                                <div className="card-body">
                                    <div className={`${style.status_listing}`}>
                                        <div className="row">
                                            <div className="col-md-12">

                                                <div className="row">
                                                    <div className="col-md-6 d-flex flex-column justify-content-center">
                                                        <div className="user-date">
                                                            <h1>Title</h1>
                                                            <input placeholder=' enter your title here' value={title}
                                                                onChange={(e) => setTitle(e.target.value)} />
                                                        </div>
                                                        <h1>Message</h1>
                                                        <textarea
                                                            placeholder="Enter your message here"
                                                            value={message}
                                                            onChange={(e) => setMessage(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                                                        <div className="text-start mb-4">
                                                            <button className={`${style.cstm_btn} btn btn-secondary me-4`} type="button" style={{ margin: '10% 100%' }} onClick={(e) => { sendNotification() }}>

                                                                {showNotifyLoader ? (
                                                                    <>
                                                                        <Spinner
                                                                            animation="border"
                                                                            size="sm"
                                                                            variant="light"
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    "Submit"
                                                                )}

                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>


                                                <hr />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                       

                        <div className="col-md-6">
                            <div className={`${style.status_listing_card} card border-secondary mb-3`}>
                                <div className={`${style.status_listing_card_header} card-header `}>
                                    <h1>Style Quiz Status</h1>
                                </div>
                                <div className="card-body">
                                <div className="form-check form-switch">
                <input 
                    className="form-check-input" 
                    type="checkbox" 
                    role="switch" 
                    id="styleQuizToggle" 
                    checked={isEnabled} // Change this based on the status (true or false)
                    onChange={handleToggleChange}
                />
                <label className="form-check-label" htmlFor="styleQuizToggle">
                    {true ? 'Enable Style Quiz' : 'Disable Style Quiz'}
                </label>
                </div>
                                   
                                </div>
                            </div>

                        </div>




                    </div>

                </div>

            </section>
        </div>
    )
}
export default UserSection;