import React, {useEffect, useState} from "react";
import style from './catalogProducts.module.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import {getRequest} from "../../Services/networkRequests";

const CatalogProducts = ()=>{
    const [showLoader, setShowLoader] = useState(false)
    const [showData,setShowData]=useState(false);
    const [data, setData] = useState([]);
    const [liveData, setliveData] = useState([]);
    const [catalogbrandcount, setcatalogbrandcount] = useState([]);


    useEffect(() => {

        fetchCatalogData();
        fetchLiveData();
        fetchCatalogBrandCount();

    }, []);



    const fetchCatalogData = async () => {
        // console.log('fetching');
        setShowLoader(true);
        const result = await getRequest('/catalog-product-count');
        if(result){
            setData(result);
            setShowData(true);
            setShowLoader(false);
        }else{
            setShowData(false);
            setShowLoader(true);
        }
    };
    const fetchLiveData = async () => {
        // console.log('fetching');
        setShowLoader(true);
        const result = await getRequest('/live-product-count');
        if(result){
            setliveData(result);
            setShowData(true);
            setShowLoader(false);
        }else{
            setShowData(false);
            setShowLoader(true);
        }
    };

    const fetchCatalogBrandCount = async () => {
        // console.log('fetching');
        setShowLoader(true);
        const result = await getRequest('/get-catalog-brands-count');
        console.log({result})
        if(result){
            setcatalogbrandcount(result);
            setShowData(true);
            setShowLoader(false);
        }else{
            setShowData(false);
            setShowLoader(true);
        }
    };
    return(
        <div>
            <ToastContainer />
            <section className={`${style.catalog_products}`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3 mb-3">
                            <div className="card" >
                                <div className="card-body">
                                    <h5 className={`${style.catalog_title} card-title`}>Total Active products in Catalog DB</h5>
                                    <h6 className="card-subtitle mb-2 text-body-secondary">{data.activeCount ? data.activeCount:'N/A'}</h6>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className={`${style.catalog_title} card-title`}>Total New Product in Catalog DB</h5>
                                    <h6 className="card-subtitle mb-2 text-body-secondary">{data.catalogCount ? data.catalogCount:'N/A'}</h6>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className={`${style.catalog_title} card-title`}>Total Active Products</h5>
                                    <h6 className="card-subtitle mb-2 text-body-secondary">{liveData.activeLiveCount ? liveData.activeLiveCount:'N/A'}</h6>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className={`${style.catalog_title} card-title`}>Total Inactive Products</h5>
                                    <h6 className="card-subtitle mb-2 text-body-secondary">{liveData.inActiveCount ? liveData.inActiveCount:'N/A'}</h6>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className={`${style.catalog_title} card-title`}>Total In stock Active products</h5>
                                    <h6 className="card-subtitle mb-2 text-body-secondary">{liveData.activeStockCount ? liveData.activeStockCount:'N/A'}</h6>

                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-3 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className={`${style.catalog_title} card-title`}>Total out of stock Active Products</h5>
                                    <h6 className="card-subtitle mb-2 text-body-secondary">{liveData.activeOutOfStockCount ? liveData.activeOutOfStockCount:'N/A'}</h6>

                                </div>
                            </div>
                        </div> */}

                        <div className="col-md-3 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className={`${style.catalog_title} card-title`}>Total catalog brands count</h5>
                                    <h6 className="card-subtitle mb-2 text-body-secondary">{catalogbrandcount.catalogBrandCount ? catalogbrandcount.catalogBrandCount:'N/A'}</h6>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
export default CatalogProducts;