import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import GradingIcon from '@mui/icons-material/Grading';
import CategoryIcon from '@mui/icons-material/Category';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PersonIcon from '@mui/icons-material/Person';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import BadgeIcon from '@mui/icons-material/Badge';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LoopIcon from '@mui/icons-material/Loop';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Outlet, useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Layout() {
  const theme = useTheme();
  const history = useNavigate();
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [open, setOpen] = React.useState(false);
    const [openScrapper, setOpenScrapper] = React.useState(true);

    const handleClick = () => {
        setOpenScrapper(!open);
    };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
    const handleLogout = () => {
        localStorage.clear();
        history('/login', {replace: true})
    };

  React.useEffect(() => {
    const authLogin = localStorage.getItem("token")
    if (authLogin === undefined || authLogin === null) {
      history('/login', {replace: true})
    } else {
      setLoggedIn(true)
    }
  }, [history])
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ backgroundColor: '#70cad1' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Atricent Dashboard
          </Typography>
            {/* Add a Logout button */}
            <IconButton
                color="inherit"
                aria-label="logout"
                onClick={handleLogout}
                sx={{
                    marginLeft: 'auto',
                }}
            >
                <ExitToAppIcon />
                Logout
            </IconButton>

        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem key={'home'} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={()=>history("/", { replace: true })}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <GradingIcon />
              </ListItemIcon>
              <ListItemText primary={'Orders'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          {/*<Divider />*/}
          {/*<ListItem key={'chat'} disablePadding sx={{ display: 'block' }}>*/}
          {/*      <ListItemButton*/}
          {/*          onClick={()=>history("/chat", { replace: true })}*/}
          {/*          sx={{*/}
          {/*              minHeight: 48,*/}
          {/*              justifyContent: open ? 'initial' : 'center',*/}
          {/*              px: 2.5,*/}
          {/*          }}*/}
          {/*      >*/}
          {/*          <ListItemIcon*/}
          {/*              sx={{*/}
          {/*                  minWidth: 0,*/}
          {/*                  mr: open ? 3 : 'auto',*/}
          {/*                  justifyContent: 'center',*/}
          {/*              }}*/}
          {/*          >*/}
          {/*              <CategoryIcon />*/}
          {/*          </ListItemIcon>*/}
          {/*          <ListItemText primary={'Chat'} sx={{ opacity: open ? 1 : 0 }} />*/}
          {/*      </ListItemButton>*/}
          {/*  </ListItem>*/}
          <Divider />
          <ListItem key={'actions'} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={()=>history("/actions", { replace: true })}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <PendingActionsIcon />
              </ListItemIcon>
              <ListItemText primary={'Actions'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>

          <ListItem key={'DailyScrapperAction'} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={()=>history("/dailyScrapperAction", { replace: true })}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <PendingActionsIcon />
              </ListItemIcon>
              <ListItemText primary={'Daily Scrapper Actions'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'BrandAmbassador'} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={()=>history("/BrandAmbassador", { replace: true })}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <PendingActionsIcon />
              </ListItemIcon>
              <ListItemText primary={'Brand Ambassador'} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
            <Divider />
            <ListItem key={'scrapperStatus'} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    onClick={()=>history("/status-listing", { replace: true })}
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <WorkHistoryIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Daily Scraper Status'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem key={'catalogScrapperStatus'} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    onClick={()=>history("/catalog-status-listing", { replace: true })}
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <WorkHistoryIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Catalog Scraper Status'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem key={'OrderScrapperStatus'} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    onClick={()=>history("/order-status-listing", { replace: true })}
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <WorkHistoryIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Order Scraper Status'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem key={'cart-status'} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    onClick={()=>history("/cart-status-listing", { replace: true })}
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <WorkHistoryIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Cart Scraper Status'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem key={'catalog-pipeline'} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    onClick={()=>history("/catalog-pipeline-status-listing", { replace: true })}
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <WorkHistoryIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Catalog Pipeline Status'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem key={'brands'} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    onClick={()=>history("/brands", { replace: true })}
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <BadgeIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Brands'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem key={'users'} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    onClick={()=>history("/user-section", { replace: true })}
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary={'User Section'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem key={'order-refund'} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    onClick={()=>history("/order-refund", { replace: true })}
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <LoopIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Order Refund'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem key={'catalog-products'} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    onClick={()=>history("/catalog-products", { replace: true })}
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <BadgeIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Catalog Products'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>


            <ListItem key={'brand-details'} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    onClick={()=>history("brand-details", { replace: true })}
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <BadgeIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Brand Details'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>


            <ListItem key={'model-pipeline'} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    onClick={()=>history("model-pipeline", { replace: true })}
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <BadgeIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Model Pipeline'} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
            {/*<Divider />*/}
            {/*<ListItem key={'cartBrandListing'} disablePadding sx={{ display: 'block' }}>*/}
            {/*    <ListItemButton*/}
            {/*        onClick={()=>history("/cart-brand-listing", { replace: true })}*/}
            {/*        sx={{*/}
            {/*            minHeight: 48,*/}
            {/*            justifyContent: open ? 'initial' : 'center',*/}
            {/*            px: 2.5,*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <ListItemIcon*/}
            {/*            sx={{*/}
            {/*                minWidth: 0,*/}
            {/*                mr: open ? 3 : 'auto',*/}
            {/*                justifyContent: 'center',*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            <BadgeIcon />*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText primary={'Cart Brand Listing'} sx={{ opacity: open ? 1 : 0 }} />*/}
            {/*    </ListItemButton>*/}
            {/*</ListItem>*/}

            {/*<Divider />*/}
            {/*<ListItem key={'catalogBrandListing'} disablePadding sx={{ display: 'block' }}>*/}
            {/*    <ListItemButton*/}
            {/*        onClick={()=>history("/catalog-brand-listing", { replace: true })}*/}
            {/*        sx={{*/}
            {/*            minHeight: 48,*/}
            {/*            justifyContent: open ? 'initial' : 'center',*/}
            {/*            px: 2.5,*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <ListItemIcon*/}
            {/*            sx={{*/}
            {/*                minWidth: 0,*/}
            {/*                mr: open ? 3 : 'auto',*/}
            {/*                justifyContent: 'center',*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            <BadgeIcon />*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText primary={'Catalog Brand Listing'} sx={{ opacity: open ? 1 : 0 }} />*/}
            {/*    </ListItemButton>*/}
            {/*</ListItem>*/}
            {/*<Divider />*/}
            {/*<ListItem key={'orderBrandListing'} disablePadding sx={{ display: 'block' }}>*/}
            {/*    <ListItemButton*/}
            {/*        onClick={()=>history("/order-brand-listing", { replace: true })}*/}
            {/*        sx={{*/}
            {/*            minHeight: 48,*/}
            {/*            justifyContent: open ? 'initial' : 'center',*/}
            {/*            px: 2.5,*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <ListItemIcon*/}
            {/*            sx={{*/}
            {/*                minWidth: 0,*/}
            {/*                mr: open ? 3 : 'auto',*/}
            {/*                justifyContent: 'center',*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            <BadgeIcon />*/}
            {/*        </ListItemIcon>*/}
            {/*        <ListItemText primary={'Order Brand Listing'} sx={{ opacity: open ? 1 : 0 }} />*/}
            {/*    </ListItemButton>*/}
            {/*</ListItem>*/}
        </List>
        
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}