import React, { useState} from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Spinner from 'react-bootstrap/Spinner';
import { postRequest } from "../../Services/networkRequests";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const NewBrandAction = () => {
    const titleStyle = { padding: '20px', width: '100%', margin: "0" }

    const [isfarfetchMenClothing, setfarfetchMenClothing] = useState(false);
    const [isfarfetchMenShoes, setfarfetchMenShoes] = useState(false);
    const [isfarfetchWomenClothing, setfarfetchWomenClothing] = useState(false);
    const [isfarfetchWomenShoes, setfarfetchWomenShoes] = useState(false);
    const [isNetPorterClothing, setNetPorterClothing] = useState(false);
    const [isNetPorterShoes, setNetPorterShoes] = useState(false);
    const [isMrPorterShoes, setMrPorterShoes] = useState(false);
    const [isMrPorterClothing, setMrPorterClothing] = useState(false);
    const [ismytheresaMenClothing, setismytheresaMenClothing] = useState(false);
    const [ismytheresamenshoes, setmytheresamenshoes] = useState(false);
    const [ismytheresawomenclothing, setmytheresawomenclothing] = useState(false);
    const [ismytheresawomenshoes, setmytheresawomenshoes] = useState(false);





    

    


    


    const farfetchMenClothing = async (e)=>{
        e.preventDefault();
        setfarfetchMenClothing(true);
        let req ={
            "command": "start-farfetch-men-clothing"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setfarfetchMenClothing(false);
    }

    const farfetchMenShoes = async (e)=>{
        e.preventDefault();
        setfarfetchMenShoes(true);
        let req ={
            "command": "start-farfetch-men-shoes"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setfarfetchMenShoes(false);
    
    
    }

    const farfetchWomenClothing = async (e)=>{
        e.preventDefault();
        setfarfetchWomenClothing(true);
        let req ={
            "command": "start-farfetch-women-clothing"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setfarfetchWomenClothing(false);
    }

    const farfetchWomenShoes = async (e)=>{
        e.preventDefault();
        setfarfetchWomenShoes(true);
        let req ={
            "command": "start-farfetch-women-shoes"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setfarfetchWomenShoes(false);
    
    
    }

    const NetPorterClothing = async (e)=>{
        e.preventDefault();
        setNetPorterClothing(true);
        let req ={
            "command": "start-net-porter-clothing"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setNetPorterClothing(false);
    
    
    }

    const netporterShoes = async (e)=>{
        e.preventDefault();
        setNetPorterShoes(true);
        let req ={
            "command": "start-net-porter-shoes"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setNetPorterShoes(false);
    
    
    }

    const mrporterClothing = async (e)=>{
        e.preventDefault();
        setMrPorterClothing(true);
        let req ={
            "command": "start-mr-porter-clothing"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setMrPorterClothing(false);
    
    
    }

    const mrportershoes= async (e)=>{
        e.preventDefault();
        setMrPorterShoes(true);
        let req ={
            "command": "start-mr-porter-shoes"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setMrPorterShoes(false);
    
    
    }

    const mytheresamenclothing = async (e)=>{
        e.preventDefault();
        setismytheresaMenClothing(true);
        let req ={
            "command": "start-mytheresa-men-clothing"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setismytheresaMenClothing(false);
    
    
    }
    

    const mytheresamenshoes = async (e)=>{
        e.preventDefault();
        setmytheresamenshoes(true);
        let req ={
            "command": "start-mytheresa-men-shoes"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setmytheresamenshoes(false);
    
    
    }

    

    
    const mytheresawomenclothing = async (e)=>{
        e.preventDefault();
        setmytheresawomenclothing(true);
        let req ={
            "command": "start-mytheresa-women-clothing"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setmytheresawomenclothing(false);
    
    
    }

    
    const mytheresawomenshoes = async (e)=>{
        e.preventDefault();
        setmytheresawomenshoes(true);
        let req ={
            "command": "start-mytheresa-women-shoes"
        }
        const result = await postRequest('/trigger-command',req);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
        setmytheresawomenshoes(false);
    
    
    }

  return (

    <div>
        <ToastContainer />
            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                <h3 style={titleStyle}>New Brand Actions</h3>
            </Box>
            <Box component={'div'}  display={'flex'}>
                <Grid container spacing={2}>

                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> farfetchMenClothing(e)}>

                                    {isfarfetchMenClothing ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Farfetch Men Clothing'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> farfetchMenShoes(e)}>

                                    {isfarfetchMenShoes ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Farfetch Men Shoes'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    
                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> farfetchWomenClothing(e)}>

                                    {isfarfetchWomenClothing ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Farfetch Women Clothing'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> farfetchWomenShoes(e)}>

                                    {isfarfetchWomenShoes ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Farfetch Women Shoes'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>


                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> NetPorterClothing(e)}>

                                    {isNetPorterClothing ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Netporter Clothing'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> netporterShoes(e)}>

                                    {isNetPorterShoes ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Netporter Shoes'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> mrportershoes(e)}>

                                    {isMrPorterShoes ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Mr Porter Shoes'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> mrporterClothing(e)}>

                                    {isMrPorterClothing ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Mr Porter Clothing'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> mytheresamenclothing(e)}>

                                    {ismytheresaMenClothing ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Theresa Men Clothing'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    

                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> mytheresamenshoes(e)}>

                                    {ismytheresamenshoes ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Theresa Men Shoes'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>


                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> mytheresawomenclothing(e)}>

                                    {ismytheresawomenclothing ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Theresa Women Clothing'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>


                    <Grid item  md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding:5}}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e)=> mytheresawomenshoes(e)}>

                                    {ismytheresawomenshoes ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Theresa Women Shoes'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>


                </Grid>
            </Box>

    </div>
    
  )
}

export default NewBrandAction