import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TablePagination from '@mui/material/TablePagination';  // Import TablePagination
import {getAddress, getAddressFormated, getBrandNames, getPrice} from '../Services/Utility';
import {getRequest, postWithLiveRequest} from '../Services/networkRequests';
import Tooltip from "@mui/material/Tooltip/Tooltip";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Modal from 'react-bootstrap/Modal';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import {blue} from "@mui/material/colors";
import AddIcon from '@mui/icons-material/Add';
import ListItemText from "@mui/material/ListItemText/ListItemText";
import DatePicker from "react-datepicker";
import Dialog from "@mui/material/Dialog/Dialog";
import {format} from "date-fns";
import {toast} from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Spinner from 'react-bootstrap/Spinner';
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const emails = ['Received', 'Confirmed', 'Shipped', 'Delivered'];

function Row(props) {
    const {row} = props;
    const [open, setOpen] = React.useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [orders, setOrders] = React.useState({})
    const [openModal, setOpenModal] = React.useState(false);
    const [ShowStatusList, setShowStatusList] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const [status, setStatus] = useState('');
    const [trackingNumber, setTrackingNumber] = useState('');
    const [collapseOrderId, setCollapseOrderId] = useState('');
    const [trackingUrl, setTrackingUrl] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    React.useEffect(() => {
        if (row.id && open) {
            async function fetchOrder() {
                console.log('rows useefect')
                let req = {
                    "OrderLimitedId": row.id
                }
                const _order = await postWithLiveRequest('open-order', req);
                if (_order.data) {
                    setOrders(_order.data)
                } else {
                    console.log(_order.data.message);
                }

            }

            fetchOrder()
        }
    }, [open])
    const handlePreview = (row) => {
        setShow(true);
        setCollapseOrderId(row)
        // setOpenPopUp(true)
    }
    const handleListItemClick = async (value) => {
        console.log('value', value);
        console.log('value', row);
        setStatus(value);
        if (value === 'Delivered') {
            let params = {
                orderId: collapseOrderId,
                status: value.toLocaleLowerCase(),
            }
            const tempRes = await postWithLiveRequest('orderDelivered', params);
            if (tempRes.status === 200) {
                toast(tempRes.data.message);
                setShow(false);
                props.refreshOrders();
            } else {
                toast('Something went wrong');
            }
            setShowStatusList(true);
            setShowForm(false);
            setShow(false);

        } else if (value === 'Confirmed') {
            // toast('Order Confirmed' );
            let params = {
                orderId: collapseOrderId,
                status: value.toLocaleLowerCase(),
            }
            const tempRes = await postWithLiveRequest('orderReceivedToConfirmed', params);
            if (tempRes.status === 200) {
                toast(tempRes.data.message);
                setShow(false);
                props.refreshOrders();
            } else {
                toast('Something went wrong');
            }
            setShowStatusList(true);
            setShowForm(false);

        } else if (value === 'Shipped') {
            setShowForm(true);
            setShowStatusList(false);
        } else {
            // toast('Status Updated' );
            setShowForm(false);
            setShowStatusList(true);
        }

    };
    const handleSubmit = async (e) => {
        e.preventDefault();
setShowLoader(true);
        const formattedDate = format(startDate, 'yyyy-MM-dd HH:mm:ss');
        let params = {
            orderId: collapseOrderId,
            status: status.toLocaleLowerCase(),
            trackingId: trackingNumber,
            deliveryDate: formattedDate,
            trackingURL: trackingUrl,
        }
        const tempRes = await postWithLiveRequest('orderConfirmed', params);
        if (tempRes.status === 200) {
            toast(tempRes.data.message);
            setShow(false);
            setShowForm(false);
            setShowStatusList(true);
        } else {
            toast('Something went wrong');

        }
        setShowLoader(false);
        props.refreshOrders()

    }
    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <span>{row.deliveryDatePassed === true ? <DoneIcon/> : ''}</span> {row.uuid ? row.uuid : 'N/A'}
                </TableCell>
                <TableCell align="left">{row.brands ? getBrandNames(row.brands) : 'N/A'}</TableCell>
                <TableCell align="left">{row.totalAmount ? '$' + getPrice(row.totalAmount) : 'N/A'}</TableCell>
                <TableCell align="left">{row.status ? row.status : 'N/A'}</TableCell>
                <TableCell align="left">{row.stripeStatus ? row.stripeStatus : 'N/A'}</TableCell>
                <TableCell align="left">{row.createdAt ? new Date(row.createdAt).toLocaleString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true,
                    timeZone: 'UTC',
                }) : 'N/A'}</TableCell>
                <TableCell align="left">{props.cols[props.cols.length - 1].renderCell(row)}</TableCell>

            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Typography variant="h6" gutterBottom component="div">
                                Details
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Brand</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>PaymentMethod</TableCell>
                                        <TableCell>shippingMethod</TableCell>
                                        <TableCell>Delivery Date</TableCell>
                                        <TableCell>Tracking ID</TableCell>
                                        <TableCell>Tracking URL</TableCell>
                                        {/*<TableCell>tax</TableCell>*/}
                                        <TableCell>Cost</TableCell>
                                        <TableCell>Products</TableCell>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orders !== null && orders.length ? (
                                        orders.map((item, index) => (
                                            item.prods.map((orderItem, itemIndex) => (
                                                <TableRow key={itemIndex}>
                                                    <TableCell align="left">{orderItem.brand ? orderItem.brand : 'N/A'}</TableCell>
                                                    <TableCell align="left">{orderItem.status ? orderItem.status : 'N/A'}</TableCell>
                                                    <TableCell align="left">{orderItem.paymentMethod.name ? orderItem.paymentMethod.name : 'N/A'}</TableCell>
                                                    <TableCell align="left">{orderItem.shippingMethod?.time ? orderItem.shippingMethod?.time : 'N/A'}</TableCell>
                                                    <TableCell align="left">{orderItem.deliveryDate ? orderItem.deliveryDate : 'N/A'}</TableCell>
                                                    <TableCell align="left">{orderItem.trackingId ? orderItem.trackingId : 'N/A'}</TableCell>
                                                    <TableCell align="left">{orderItem.trackingurl ? orderItem.trackingurl : 'N/A'}</TableCell>
                                                    <TableCell align="left">{orderItem.totalCost ? orderItem.totalCost : 'N/A'}</TableCell>
                                                    <TableCell align="left">
                                                        <div>
                                                            {orderItem.variations.filter(varItem => varItem && varItem.link).length > 0 ? (
                                                                orderItem.variations.map((varItem, varIndex) => (
                                                                    varItem && varItem.link && (
                                                                        <a href={varItem.link} target='_blank' key={varIndex}>
                                                                            {varItem.title}
                                                                        </a>
                                                                    )
                                                                ))
                                                            ) : (
                                                                <span>N/A</span>
                                                            )}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {orderItem.address.address ? getAddress(orderItem.address) : 'N/A'}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Box display={"flex"} justifyContent="space-around" width={"100%"}>
                                                            <Tooltip title="Edit" placement="left-start">
                                                                <IconButton onClick={() => handlePreview(orderItem.id)} sx={{ boxShadow: "0px 0px 10px rgba(37, 133, 202, 0.2)" }}>
                                                                    <DriveFileRenameOutlineIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete" placement="right-start">
                                                                <IconButton sx={{ boxShadow: "0px 0px 10px rgba(37, 133, 202, 0.2)" }}>
                                                                    <DeleteOutlineIcon color="error" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell className="text-center mt-3 mb-3">Information Not Available</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>

                                {/*<TableBody>*/}
                                {/*    {order.hasOwnProperty("id") ? (*/}
                                {/*        order.prods.map((orderItem) => (*/}
                                {/*            <TableRow>*/}
                                {/*                <TableCell align="left">{orderItem.brand ? orderItem.brand : 'N/A'}</TableCell>*/}
                                {/*                <TableCell align="left">{orderItem.status ? orderItem.status : 'N/A'}</TableCell>*/}
                                {/*                <TableCell align="left">{orderItem.paymentMethod.name ? orderItem.paymentMethod.name : 'N/A'}</TableCell>*/}
                                {/*                <TableCell align="left">{orderItem.shippingMethod?.time ? orderItem.shippingMethod?.time : 'N/A'}</TableCell>*/}
                                {/*                <TableCell align="left">{orderItem.deliveryDate ? orderItem.deliveryDate : 'N/A'}</TableCell>*/}
                                {/*                <TableCell align="left">{orderItem.trackingId ? orderItem.trackingId : 'N/A'}</TableCell>*/}
                                {/*                <TableCell align="left">{orderItem.trackingurl ? orderItem.trackingurl : 'N/A'}</TableCell>*/}
                                {/*                <TableCell align="left">{orderItem.totalCost ? orderItem.totalCost : 'N/A'}</TableCell>*/}
                                {/*                <TableCell align="left">*/}
                                {/*                    <div>*/}
                                {/*                        {orderItem.variations.filter(varItem => varItem && varItem.link).length > 0 ? (*/}
                                {/*                            orderItem.variations.map((varItem, varIndex) => (*/}
                                {/*                                varItem && varItem.link && (*/}
                                {/*                                    <a href={varItem.link} target='_blank' key={varIndex}>*/}
                                {/*                                        {varItem.title}*/}
                                {/*                                    </a>*/}
                                {/*                                )*/}
                                {/*                            ))*/}
                                {/*                        ) : (*/}
                                {/*                            <span>N/A</span>*/}
                                {/*                        )}*/}
                                {/*                    </div>*/}
                                {/*                </TableCell>*/}
                                {/*                <TableCell align="left">*/}
                                {/*                    {orderItem.address.address ? getAddress(orderItem.address) : 'N/A'}*/}
                                {/*                </TableCell>*/}
                                {/*                <TableCell align="left">*/}
                                {/*                    <Box display={"flex"} justifyContent="space-around" width={"100%"}>*/}
                                {/*                        <Tooltip title="Edit" placement="left-start">*/}
                                {/*                            <IconButton onClick={() => handlePreview(orderItem.id)} sx={{ boxShadow: "0px 0px 10px rgba(37, 133, 202, 0.2)" }}>*/}
                                {/*                                <DriveFileRenameOutlineIcon />*/}
                                {/*                            </IconButton>*/}
                                {/*                        </Tooltip>*/}
                                {/*                        <Tooltip title="Delete" placement="right-start">*/}
                                {/*                            <IconButton sx={{ boxShadow: "0px 0px 10px rgba(37, 133, 202, 0.2)" }}>*/}
                                {/*                                <DeleteOutlineIcon color="error" />*/}
                                {/*                            </IconButton>*/}
                                {/*                        </Tooltip>*/}
                                {/*                    </Box>*/}
                                {/*                </TableCell>*/}
                                {/*            </TableRow>*/}
                                {/*        ))*/}
                                {/*    ) : (*/}
                                {/*        <TableRow>*/}
                                {/*            <TableCell className="text-center mt-3 mb-3">Information Not Available</TableCell>*/}
                                {/*        </TableRow>*/}
                                {/*    )}*/}
                                {/*</TableBody>*/}

                            </Table>
                        </Box>
                        <Modal show={show} onHide={handleClose} backdrop="static" centered>
                            <Modal.Body>
                                <div className="text-end" onClick={handleClose} style={{cursor: "pointer"}}>
                                    <CloseIcon/>
                                </div>
                                <Box>

                                    {ShowStatusList &&
                                    <>
                                        <List sx={{pt: 0}}>
                                            {emails.map((email) => (
                                                <ListItem disableGutters key={email}>
                                                    <ListItemButton onClick={() => handleListItemClick(email)}>
                                                        <ListItemAvatar>
                                                            <Avatar sx={{bgcolor: blue[100], color: blue[600]}}>
                                                                <AddIcon/>
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={email}/>
                                                    </ListItemButton>
                                                </ListItem>
                                            ))}

                                        </List>
                                    </>
                                    }

                                    {showForm &&
                                    <>
                                        <button className="btn btn-secondary w-25 m-auto" onClick={(e) => {
                                            setShowStatusList(true);
                                            setShowForm(false);
                                        }}>back
                                        </button>

                                        <div className="p-5">
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <label htmlFor="trackingNumber">Tracking Number</label>
                                                    <input type="text" className="form-control" id="trackingNumber"
                                                           aria-describedby="emailHelp"
                                                           onChange={(e) => setTrackingNumber(e.target.value)}
                                                           placeholder="Tracking Number" required={true}/>

                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="trackingNumber">Tracking URL</label>
                                                    <input type="text" className="form-control" id="trackingUrl"
                                                           aria-describedby="emailHelp"
                                                           onChange={(e) => setTrackingUrl(e.target.value)}
                                                           placeholder="Tracking Url" required={true}/>

                                                </div>
                                                <div className="form-group cstm-form-group">
                                                    <label htmlFor="deliveryDate" className="d-block">Delivery
                                                        Date</label>
                                                    <DatePicker className="form-control w-100" selected={startDate}
                                                                onChange={(date) => setStartDate(date)}/>
                                                </div>
                                                <button type="submit" className="btn btn-primary mt-2 w-100" style={{
                                                    backgroundColor: '#bbdefb',
                                                    color: '#1e88e5',
                                                    borderColor: '#bbdefb'
                                                }}>
                                                    {showLoader ? (
                                                        <>
                                                            <Spinner
                                                                animation="border"
                                                                size="lg"
                                                                variant="light"
                                                            />
                                                        </>
                                                    ) : (
                                                        'Submit'
                                                    )}

                                                </button>
                                            </form>
                                        </div>
                                    </>
                                    }
                                </Box>
                            </Modal.Body>

                        </Modal>
                    </Collapse>
                </TableCell>
            </TableRow>


        </React.Fragment>
    );
}

export default function ExpandableTable(props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);  // Set the number of rows per page
    const [searchQuery, setSearchQuery] = useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPage(0); // Reset page when search query changes
    };
    const handleBrandFilter = (brandName) => {
        setSearchQuery(brandName);
        setPage(0); // Reset page when brand filter changes
    };

// Filter data based on search query and brand name
    const filteredData = props.tableData.filter((row) => {
        const matchesSearch = Object.values(row).some((value) =>
            String(value).toLowerCase().includes(searchQuery.toLowerCase())
        );
        const brandName = row.brands && row.brands.length > 0 ? row.brands[0].name : '';
        const matchesBrand = brandName.toLowerCase().includes(searchQuery.toLowerCase());
        return matchesSearch || matchesBrand;
    });
    return (
        <>
            {/*<input*/}
            {/*    type="text"*/}
            {/*    className="form-control w-25 float-end mb-5 me-4"*/}
            {/*    id="trackingNumber"*/}
            {/*    aria-describedby="emailHelp"*/}
            {/*    placeholder="Search by Brand Name"*/}
            {/*    value={searchQuery}*/}
            {/*    onChange={handleSearchChange}*/}
            {/*/>*/}

            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            {props.cols.map(coloumn => (
                                <TableCell key={coloumn.field}>{coloumn.headerName}</TableCell>
                            ))}
                            {/* <TableCell>Dessert (100g serving)</TableCell>
                        <TableCell align="right">Calories</TableCell>
                        <TableCell align="right">Fat&nbsp;(g)</TableCell>
                        <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                        <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((row) => (
                            <Row key={row.uuid} row={row} cols={props.cols} refreshOrders={props.refreshOrders}/>
                        ))}
                    </TableBody>
                </Table>
                {/*<TablePagination*/}
                {/*    rowsPerPageOptions={[10, 25, 50]}  // Set the available rows per page options*/}
                {/*    component="div"*/}
                {/*    count={props.tableData.length}*/}
                {/*    rowsPerPage={rowsPerPage}*/}
                {/*    page={page}*/}
                {/*    onPageChange={handleChangePage}*/}
                {/*    onRowsPerPageChange={handleChangeRowsPerPage}*/}
                {/*/>*/}
            </TableContainer>
        </>
    );
}
