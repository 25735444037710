import React, { useState, useEffect,useRef } from 'react';
import style from './brands.module.css';
import { getRequest ,postRequest} from '../../Services/networkRequests';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import 'datatables.net-bs5'
const Brands = () =>{
    const [showAppData,setShowAppData]=useState(false);
    const [showAppLoader, setShowAppLoader] = useState(false)
    const [brandAppData, setBrandAppData] = useState([]);
    const dataTableAppRef = useRef(null);

    const [showDailyData,setShowDailyData]=useState(false);
    const [showDailyLoader, setShowDailyLoader] = useState(false)
    const [brandDailyData, setBrandDailyData] = useState([]);
    const dataTableDailyRef = useRef(null);

    const [showCartData,setShowCartData]=useState(false);
    const [showCartLoader, setShowCartLoader] = useState(false)
    const [brandCartData, setBrandCartData] = useState([]);
    const dataTableCartRef = useRef(null);

    const [showCatalogData,setShowCatalogData]=useState(false);
    const [showCatalogLoader, setShowCatalogLoader] = useState(false)
    const [brandCatalogData, setBrandCatalogData] = useState([]);
    const dataTableCatalogRef = useRef(null);

    const [showOrderData,setShowOrderData]=useState(false);
    const [showOrderLoader, setShowOrderLoader] = useState(false)
    const [brandOrderData, setBrandOrderData] = useState([]);
    const dataTableOrderRef = useRef(null);

    useEffect(() => {

           fetchAppData();
          fetchDailyData();
         fetchCartData();
        fetchCatalogData();
        fetchOrderData();

        }, []);


    useEffect(() => {
        if (showAppData && dataTableAppRef.current) {
            $(dataTableAppRef.current).DataTable({
                aaSorting: [], // Empty array disables initial sorting
                lengthMenu: [5, 10, 25],
                pageLength: 5, // Set the initial page length to 5 if you want
            });
        }
    }, [showAppData]);

    useEffect(() => {
        if (showDailyData && dataTableDailyRef.current) {
            $(dataTableDailyRef.current).DataTable({
                aaSorting: [], // Empty array disables initial sorting
                lengthMenu: [5, 10, 25],
                pageLength: 5, // Set the initial page length to 5 if you want
            });
        }
    }, [showDailyData]);
    useEffect(() => {
        if (showCartData && dataTableCartRef.current) {
            $(dataTableCartRef.current).DataTable({
                aaSorting: [], // Empty array disables initial sorting
                lengthMenu: [5, 10, 25],
                pageLength: 5, // Set the initial page length to 5 if you want
            });
        }
    }, [showCartData]);
    useEffect(() => {
        if (showCatalogData && dataTableCatalogRef.current) {
            $(dataTableCatalogRef.current).DataTable({
                aaSorting: [], // Empty array disables initial sorting
                lengthMenu: [5, 10, 25],
                pageLength: 5, // Set the initial page length to 5 if you want
            });
        }
    }, [showCatalogData]);

    useEffect(() => {
        if (showOrderData && dataTableOrderRef.current) {
            $(dataTableOrderRef.current).DataTable({
                aaSorting: [], // Empty array disables initial sorting
                lengthMenu: [5, 10, 25],
                pageLength: 5, // Set the initial page length to 5 if you want
            });
        }
    }, [showOrderData]);

    const fetchAppData = async () => {
        setShowAppLoader(true);
        const result = await getRequest('/get-brands-list');
        if(result.data){
            setBrandAppData(result.data);
            setShowAppData(true);
            setShowAppLoader(false);
        }else{
            toast('Something went wrong' );
            setShowAppData(false);
            setShowAppLoader(true);
        }
    };
    const fetchDailyData = async () => {
        setShowDailyLoader(true);
        const result = await getRequest('/get-daily-brands-list');
        if(result.data){
            setBrandDailyData(result.data);
            setShowDailyData(true);
            setShowDailyLoader(false);
        }else{
            toast('Something went wrong' );
            setShowDailyData(false);
            setShowDailyLoader(true);
        }
    };
    const fetchCartData = async () => {
        setShowCartLoader(true);
        const result = await getRequest('/get-brands-list');
        if(result.data){
            setBrandCartData(result.data);
            setShowCartData(true);
            setShowCartLoader(false);
        }else{
            toast('Something went wrong' );
            setShowCartData(false);
            setShowCartLoader(true);
        }
    };
    const fetchCatalogData = async () => {
        setShowCatalogLoader(true);
        const result = await getRequest('/get-catalog-brands-list');
        if(result.data){
            setBrandCatalogData(result.data);
            setShowCatalogData(true);
            setShowCatalogLoader(false);
        }else{
            toast('Something went wrong' );
            setShowCatalogData(false);
            setShowCatalogLoader(true);
        }
    };
    const fetchOrderData = async () => {
        setShowOrderLoader(true);
        const result = await getRequest('/get-order-brands-list');
        if(result.data){
            setBrandOrderData(result.data);
            setShowOrderData(true);
            setShowOrderLoader(false);
        }else{
            toast('Something went wrong' );
            setShowOrderData(false);
            setShowOrderLoader(true);
        }
    };
    const handleAppStatusChange = async (index) => {
        const updatedBrands = [...brandAppData];
        updatedBrands[index].status = 1 - updatedBrands[index].status; // Toggle the status
        setBrandAppData(updatedBrands);
        const result = await postRequest('/change-brand-status',updatedBrands[index]);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
    };
    const handleCartStatusChange = async (index) => {
        const updatedBrands = [...brandCartData];
        updatedBrands[index].status = 1 - updatedBrands[index].status; // Toggle the status
        setBrandCartData(updatedBrands);
        const result = await postRequest('/change-brand-status',updatedBrands[index]);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
    };
    const handleCatalogStatusChange = async (index) => {
        const updatedBrands = [...brandCatalogData];
        updatedBrands[index].status = 1 - updatedBrands[index].status; // Toggle the status
        setBrandCatalogData(updatedBrands);
        const result = await postRequest('/change-catalog-scrapper-status',updatedBrands[index]);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
    };
    const handleDailyStatusChange = async (index) => {
        const updatedBrands = [...brandDailyData];
        updatedBrands[index].status = 1 - updatedBrands[index].status; // Toggle the status
        setBrandDailyData(updatedBrands);
        const result = await postRequest('/change-daily-scrapper-status',updatedBrands[index]);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
    };
    const handleOrderStatusChange = async (index) => {
        const updatedBrands = [...brandOrderData];
        updatedBrands[index].status = 1 - updatedBrands[index].status; // Toggle the status
        setBrandOrderData(updatedBrands);
        const result = await postRequest('/change-order-scrapper-status',updatedBrands[index]);
        if(result.status === 200){
            toast(result.data.message );
        }else{
            toast('Something went wrong' );
        }
    };



    return(
        <div>
            <section className={`${style.status_listing_section}`}>
                <ToastContainer />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">

                    <div className={`${style.status_listing_card} card border-secondary mb-3`}>
                        <div className={`${style.status_listing_card_header} card-header `}>
                            <h1>Buy From App Listing</h1>
                        </div>
                        <div className="card-body">
                            <div className={`${style.status_listing}`}>
                                <div className="row">
                                    <div className="col-md-12">
                                        {showAppData &&
                                        <div className="table-responsive">
                                            <table className="table table-hover table-bordered"
                                                   ref={dataTableAppRef} id="appDataTable"
                                            >
                                                <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {brandAppData !== null && brandAppData.length ? (
                                                    brandAppData.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.name}</td>
                                                            <td>
                                                                <div className="form-check form-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        role="switch"
                                                                        id={`flexAppSwitchCheck${index}`}
                                                                        checked={item.status === 1}
                                                                        onChange={() => handleAppStatusChange(index)}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor={`flexAppSwitchCheck${index}`}
                                                                    >
                                                                        {item.status === 1 ? 'on' : 'off'}
                                                                    </label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <></>
                                                )}



                                                </tbody>
                                            </table>
                                        </div>

                                        }
                                        {showAppLoader &&
                                        <>
                                            <div className="text-center pt-5">
                                                <Spinner animation="border" role="status" size="lg" style={{
                                                    height: '3rem',
                                                    width: '3rem'
                                                }}>
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </div>
                                        </>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                        </div>


                        <div className="col-md-6">

                            <div className={`${style.status_listing_card} card border-secondary mb-3`}>
                                <div className={`${style.status_listing_card_header} card-header `}>
                                    <h1>Daily Brand Scraper Listing</h1>
                                </div>
                                <div className="card-body">
                                    <div className={`${style.status_listing}`}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {showDailyData &&
                                                <div className="table-responsive">
                                                    <table
                                                        className="table table-hover table-bordered"
                                                        ref={dataTableDailyRef}
                                                        id="dailyDataTable"
                                                    >
                                                        <thead>
                                                        <tr>

                                                            <th scope="col">Name</th>
                                                            <th scope="col">Status</th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {brandDailyData !== null && brandDailyData.length ? (
                                                            brandDailyData.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.name}</td>
                                                                    <td>
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                role="switch"
                                                                                id={`flexDailySwitchCheck${index}`}
                                                                                checked={item.status === 1}
                                                                                onChange={() => handleDailyStatusChange(index)}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={`flexDailySwitchCheck${index}`}
                                                                            >
                                                                                {item.status === 1 ? 'on' : 'off'}
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <></>
                                                        )}



                                                        </tbody>
                                                    </table>
                                                </div>

                                                }
                                                {showDailyLoader &&
                                                <>
                                                    <div className="text-center pt-5">
                                                        <Spinner animation="border" role="status" size="lg" style={{
                                                            height: '3rem',
                                                            width: '3rem'
                                                        }}>
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                </>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6">

                            <div className={`${style.status_listing_card} card border-secondary mb-3`}>
                                <div className={`${style.status_listing_card_header} card-header `}>
                                    <h1>Cart Brand Scraper Listing</h1>
                                </div>
                                <div className="card-body">
                                    <div className={`${style.status_listing}`}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {showDailyData &&
                                                <div className="table-responsive">
                                                    <table
                                                        className="table table-hover table-bordered"
                                                        ref={dataTableCartRef}
                                                        id="dailyDataTable"
                                                    >
                                                        <thead>
                                                        <tr>

                                                            <th scope="col">Name</th>
                                                            <th scope="col">Status</th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {brandCartData !== null && brandCartData.length ? (
                                                            brandCartData.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.name}</td>
                                                                    <td>
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                role="switch"
                                                                                id={`flexCartSwitchCheck${index}`}
                                                                                checked={item.status === 1}
                                                                                onChange={() => handleCartStatusChange(index)}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={`flexCartSwitchCheck${index}`}
                                                                            >
                                                                                {item.status === 1 ? 'on' : 'off'}
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <></>
                                                        )}



                                                        </tbody>
                                                    </table>
                                                </div>

                                                }
                                                {showCartLoader &&
                                                <>
                                                    <div className="text-center pt-5">
                                                        <Spinner animation="border" role="status" size="lg" style={{
                                                            height: '3rem',
                                                            width: '3rem'
                                                        }}>
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                </>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6">

                            <div className={`${style.status_listing_card} card border-secondary mb-3`}>
                                <div className={`${style.status_listing_card_header} card-header `}>
                                    <h1>Catalog Brand Scraper Listing</h1>
                                </div>
                                <div className="card-body">
                                    <div className={`${style.status_listing}`}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {showCatalogData &&
                                                <div className="table-responsive">
                                                    <table className="table table-hover table-bordered"
                                                           ref={dataTableCatalogRef}
                                                    >
                                                        <thead>
                                                        <tr>

                                                            <th scope="col">Name</th>
                                                            <th scope="col">Status</th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {brandCatalogData !== null && brandCatalogData.length ? (
                                                            brandCatalogData.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.name}</td>
                                                                    <td>
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                role="switch"
                                                                                id={`flexCatalogSwitchCheck${index}`}
                                                                                checked={item.status === 1}
                                                                                onChange={() => handleCatalogStatusChange(index)}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={`flexCatalogSwitchCheck${index}`}
                                                                            >
                                                                                {item.status === 1 ? 'on' : 'off'}
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <></>
                                                        )}



                                                        </tbody>
                                                    </table>
                                                </div>

                                                }
                                                {showCatalogLoader &&
                                                <>
                                                    <div className="text-center pt-5">
                                                        <Spinner animation="border" role="status" size="lg" style={{
                                                            height: '3rem',
                                                            width: '3rem'
                                                        }}>
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                </>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">

                            <div className={`${style.status_listing_card} card border-secondary mb-3`}>
                                <div className={`${style.status_listing_card_header} card-header `}>
                                    <h1>Order Brand Scraper Listing</h1>
                                </div>
                                <div className="card-body">
                                    <div className={`${style.status_listing}`}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {showOrderData &&
                                                <div className="table-responsive">
                                                    <table className="table table-hover table-bordered"
                                                           ref={dataTableOrderRef}
                                                    >
                                                        <thead>
                                                        <tr>

                                                            <th scope="col">Name</th>
                                                            <th scope="col">Status</th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {brandOrderData !== null && brandOrderData.length ? (
                                                            brandOrderData.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.name}</td>
                                                                    <td>
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                role="switch"
                                                                                id={`flexOrderSwitchCheck${index}`}
                                                                                checked={item.status === 1}
                                                                                onChange={() => handleOrderStatusChange(index)}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={`flexOrderSwitchCheck${index}`}
                                                                            >
                                                                                {item.status === 1 ? 'on' : 'off'}
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <></>
                                                        )}



                                                        </tbody>
                                                    </table>
                                                </div>

                                                }
                                                {showOrderLoader &&
                                                <>
                                                    <div className="text-center pt-5">
                                                        <Spinner animation="border" role="status" size="lg" style={{
                                                            height: '3rem',
                                                            width: '3rem'
                                                        }}>
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                </>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </div>
    )
}
export default Brands;