import React, { useState, useEffect,useRef } from 'react';
import style from './catalogBrandListing.module.css';
import { getRequest ,postRequest} from '../../Services/networkRequests';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import 'datatables.net-bs5'
// import DataTable from 'datatables.net-bs5';
// import DataTable from 'datatables.net-dt';
const CatalogBrandListing = () =>{
    const [showData,setShowData]=useState(false);
    const [showLoader, setShowLoader] = useState(false)
    const [brandData, setBrandData] = useState([]);
    const dataTableRef = useRef(null);
    useEffect(() => {

        fetchData();

    }, []);

    useEffect(() => {
        // Initialize DataTable when the component mounts
        if (showData) {
            $(dataTableRef.current).DataTable({
                aaSorting: [] // Empty array disables initial sorting
            });        }
    }, [showData]);

    const fetchData = async () => {
        // console.log('fetching');
        setShowLoader(true);
        const result = await getRequest('/get-catalog-brands-list');
        if(result.data){
            setBrandData(result.data);
            setShowData(true);
            setShowLoader(false);
        }else{
            setShowData(false);
            setShowLoader(true);
        }



    };
    const handleStatusChange = async (index) => {
        const updatedBrands = [...brandData];
        updatedBrands[index].status = 1 - updatedBrands[index].status; // Toggle the status
        setBrandData(updatedBrands);

        // const result = await postRequest('/change-brand-status',updatedBrands[index]);
        // if(result.status === 200){
        //     toast(result.data.message );
        // }

    };



    return(
        <div>
            <section className={`${style.status_listing_section}`}>
                <ToastContainer />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">

                            <div className={`${style.status_listing_card} card border-secondary mb-3`}>
                                <div className={`${style.status_listing_card_header} card-header `}>
                                    <h1>Catalog Brand Scraper Listing</h1>
                                </div>
                                <div className="card-body">
                                    <div className={`${style.status_listing}`}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {showData &&
                                                <div className="table-responsive">
                                                    <table className="table table-hover table-bordered"
                                                           ref={dataTableRef}
                                                    >
                                                        <thead>
                                                        <tr>

                                                            <th scope="col">Name</th>
                                                            <th scope="col">Status</th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {brandData !== null && brandData.length ? (
                                                            brandData.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.name}</td>
                                                                    <td>
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                role="switch"
                                                                                id={`flexSwitchCheck${index}`}
                                                                                checked={item.status === 1}
                                                                                onChange={() => handleStatusChange(index)}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={`flexSwitchCheck${index}`}
                                                                            >
                                                                                {item.status === 1 ? 'on' : 'off'}
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <></>
                                                        )}



                                                        </tbody>
                                                    </table>
                                                </div>

                                                }
                                                {showLoader &&
                                                <>
                                                    <div className="text-center pt-5">
                                                        <Spinner animation="border" role="status" size="lg" style={{
                                                            height: '5rem',
                                                            width: '5rem'
                                                        }}>
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                </>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>

                </div>
            </section>
        </div>
    )
}
export default CatalogBrandListing;