import React, { useState, useEffect } from 'react';
import { Table, Pagination , Dropdown } from 'react-bootstrap';
import { getOrderwithLiveRequest , postWithLiveRequest} from '../../Services/networkRequests'
import {toast} from "react-toastify";

const BrandAmbassador = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState(null); // Added status filter state

  useEffect(() => {
    fetchBrandAmbassadors(currentPage);
  }, [currentPage]);

  const fetchBrandAmbassadors = async (page) => {
    try {
      const response = await getOrderwithLiveRequest(`brand-ambassador-listing/${page}`)
      console.log('response' , response)
      // const response = await axios.get(`getOrderwithLiveRequest/${page}`);
      const { BrandAmbassadors, totalPages, totalRecords } = response
      ;
      setData(BrandAmbassadors);
      setTotalPages(totalPages);
      setTotalRecords(totalRecords);
    } catch (error) {
      console.error('Error fetching brand ambassadors:', error);
    }
  };

  const handleStatusChange = async (id , newStatus) => {
    let params = {
      brandAmbassadorID: id,
      status: newStatus.toLocaleLowerCase(),
  }
      const tempRes = await postWithLiveRequest('brand-ambassador-status', params);
      console.log('tempRes' , tempRes)
      if (tempRes.status === 200) {
        toast('status updated successfully');
        fetchBrandAmbassadors(currentPage);
        
    } else {
        toast('Something went wrong');
    }
  }

  const handleStatusFilter = (status) => {
    setStatusFilter(status === 'all' ? null : status);
  };

  // Filter data based on status
  const filteredData = statusFilter ? data.filter(record => record.status === statusFilter) : data;


 

    // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <h2>Brand Ambassador</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Age</th>
            <th>Contact</th>
            <th style={{ display: 'flex', alignItems: 'center' , justifyContent:'space-between'}}>
                <span>Status</span>
                <Dropdown style={{ marginLeft: '5px' }}>
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <i className="bi bi-caret-down-fill"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleStatusFilter('all')}>All</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleStatusFilter('pending')}>Pending</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleStatusFilter('approved')}>Approved</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleStatusFilter('rejected')}>Rejected</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </th>
            <th>User ID</th>
            <th>Social Media URL</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
        {filteredData.map(record => (
            <tr key={record.id}>
              <td>{record.id}</td>
              <td>{record.first_name}</td>
              <td>{record.last_name}</td>
              <td>{record.age}</td>
              <td>{record.contact}</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant={record.status === 'pending' ? 'secondary' : record.status === 'approved' ? 'success' : record.status === 'rejected' ? 'danger' : 'primary'} id={`statusDropdown${record.id}`} className={`status-toggle ${record.status === 'pending' ? 'pending' : record.status === 'approved' ? 'approved' : 'rejected'}`}>
                    {record.status}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    { record.status === 'pending' && (
                      <>
                      <Dropdown.Item onClick={() => handleStatusChange(record.id, 'approved')}>Approved</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleStatusChange(record.id, 'rejected')}>Rejected</Dropdown.Item>
                      </>
                    )}
                     { record.status === 'approved' && (
                      <>
                      <Dropdown.Item onClick={() => handleStatusChange(record.id, 'pending')}>Pending</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleStatusChange(record.id, 'rejected')}>Rejected</Dropdown.Item>
                      </>
                    )}
                     { record.status === 'rejected' && (
                      <>
                      <Dropdown.Item onClick={() => handleStatusChange(record.id, 'approved')}>Approved</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleStatusChange(record.id, 'pending')}>Pending</Dropdown.Item>
                      </>
                    )}
                  
                  </Dropdown.Menu>
                </Dropdown>
              </td>
              <td>{record.user}</td>
              <td>{record.social_media_url}</td>
              <td>{record.createdAt}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination>
        {Array.from({ length: totalPages }).map((_, index) => (
          <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </div>
  )
}

export default BrandAmbassador