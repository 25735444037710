// import React from 'react';
// import { Grid, Paper, Typography, Box } from '@mui/material';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// const Flowchart = () => {
//   const boxStyle = {
//     padding: '16px',
//     textAlign: 'center',
//     border: '1px solid black',
//   };

//   const arrowStyle = {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     margin: '0 8px', 
//     width: '50px'
//   };

//   const arrowStyleClassifier = {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     marginLeft: '35%',
//     width: '50px'
//   };

//   return (
//     <Grid container spacing={2} style={{ padding: '20px' }}>
//       {/* Row 1 */}
//       <Grid item xs={2}>
//         <Paper elevation={3} style={boxStyle}>
//           <Typography>Download 1</Typography>
//         </Paper>
//       </Grid>
//       <Grid item xs={1} style={arrowStyle}>
//         <ArrowForwardIcon />
//       </Grid>
//       <Grid item xs={2}>
//         <Paper elevation={3} style={boxStyle}>
//           <Typography>Invalid 1</Typography>
//         </Paper>
//       </Grid>
//       <Grid item xs={1} style={arrowStyle}>
//         <ArrowForwardIcon />
//       </Grid>
//       <Grid item xs={2}>
//         <Paper elevation={3} style={boxStyle}>
//           <Typography>Download 2</Typography>
//         </Paper>
//       </Grid>
//       <Grid item xs={1} style={arrowStyle}>
//         <ArrowForwardIcon />
//       </Grid>
//       <Grid item xs={2}>
//         <Paper elevation={3} style={boxStyle}>
//           <Typography>Invalid 2</Typography>
//         </Paper>
//       </Grid>

//       {/* Row 2 */}
//       <Grid item xs={2} style={{ marginTop: '20px' }}>
//         <Paper elevation={3} style={boxStyle}>
//           <Typography>Download 3</Typography>
//         </Paper>
//       </Grid>
//       <Grid item xs={1} style={{ ...arrowStyle, marginTop: '20px' }}>
//         <ArrowForwardIcon />
//       </Grid>
//       <Grid item xs={2} style={{ marginTop: '20px' }}>
//         <Paper elevation={3} style={boxStyle}>
//           <Typography>Invalid 3</Typography>
//         </Paper>
//       </Grid>
//       <Grid item xs={1} style={{ ...arrowStyle, marginTop: '20px' }}>
//         <ArrowForwardIcon />
//       </Grid>
//       <Grid item xs={2} style={{ marginTop: '20px' }}>
//         <Paper elevation={3} style={boxStyle}>
//           <Typography>Upload</Typography>
//         </Paper>
//       </Grid>
//       <Grid item xs={1} style={{ ...arrowStyle, marginTop: '20px' }}>
//         <ArrowForwardIcon />
//       </Grid>
//       <Grid item xs={2} style={{ marginTop: '20px' }}>
//         <Paper elevation={3} style={boxStyle}>
//           <Typography>Update</Typography>
//         </Paper>
//       </Grid>

//       <Grid item xs={1} style={{ ...arrowStyleClassifier, marginTop: '20px' }}>
//         <ArrowForwardIcon />
//       </Grid>
     
//       <Grid item xs={2} style={{ marginTop: '20px' , marginLeft:'-2%'}}>
//         <Paper elevation={3} style={boxStyle}>
//           <Typography>Classifier</Typography>
//         </Paper>
//       </Grid>

//       {/* Row 4 (Classifier outputs with down arrows) */}
//       <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop:"20px" }}>
//         <Grid container item xs={9} spacing={2} justifyContent="space-around">
//             <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//               <ArrowDownwardIcon style={{fontSize:"30px"}}/>
//               <Paper elevation={3} style={boxStyle}>
//                 <Typography>Segment</Typography>
//               </Paper>
//             </Grid>

//             <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//                 <ArrowDownwardIcon style={{fontSize:"30px"}}/>
//                 <Paper elevation={3} style={boxStyle}>
//                   <Typography>Description</Typography>
//                 </Paper>
//             </Grid>

//             <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//                 <ArrowDownwardIcon style={{fontSize:"30px"}}/>
//                 <Paper elevation={3} style={boxStyle}>
//                   <Typography>Jina Emb</Typography>
//                 </Paper>
//             </Grid>
//         </Grid>
//       </Grid>

//       {/* Row 5 (Segment and Jina Emb outputs) */}
//       <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop:"20px" }}>
//         <Grid container item xs={0} spacing={0} justifyContent="space-around">
//             <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//                 <ArrowDownwardIcon style={{fontSize:"30px"}}/>
//                 <Paper elevation={3} style={boxStyle}>
//                   <Typography>Chang Name</Typography>
//                 </Paper>
//             </Grid>
//             <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//                 <ArrowDownwardIcon style={{fontSize:"30px"}}/>
//                 <Paper elevation={3} style={boxStyle}>
//                   <Typography>Emb to pfel</Typography>
//                 </Paper>
//             </Grid>
//         </Grid>
//       </Grid>


//       {/* Row 6 (Upload Var Image) */}
//       <Grid item xs={0} style={{ marginTop: '20px', marginLeft:"20%" , display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
//         <ArrowDownwardIcon style={{fontSize:"30px"}}/>
//         <Paper elevation={3} style={boxStyle}>
//           <Typography>Upload Var Image</Typography>
//         </Paper>
//       </Grid>

//       <Grid item xs={0} style={{  marginTop:"150px" }}>
//       <Grid item xs={0} style={{ marginTop: '20px', marginLeft:"-220%" , display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
//         <ArrowDownwardIcon style={{fontSize:"30px"}}/>
//         <Paper elevation={3} style={boxStyle}>
//           <Typography>Upload Var Image</Typography>
//         </Paper>
//       </Grid>
//       </Grid>

//     </Grid>
//   );
// };

// export default Flowchart;


































import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import axios from 'axios';

const Flowchart = () => {
  const [modelStatuses, setModelStatuses] = useState([]);

  useEffect(() => {
    // Fetch model statuses from API
    const fetchStatuses = async () => {
      try {
        const response = await axios.get('https://admin-api.atricent.com/api/v1/get-model-structure');
        setModelStatuses(response.data.models || []);
        console.log({modelStatuses})
      } catch (error) {
        console.error('Error fetching model statuses:', error);
      }
    };

    fetchStatuses();
  }, []);

  // Map status to border colors
  const getBorderColor = (status) => {
    switch (status) {
      case 'pending':
        return 'yellow';
      case 'running':
        return 'blue';
      case 'stopped':
        return 'red';
      case 'completed':
        return 'green';
      default:
        return 'black';
    }
  };

  // Find status for a given model name
  const getStatus = (name) => {
    const model = modelStatuses.find((item) => item.name.toLowerCase() === name.toLowerCase());
    console.log(' model.status', model)
    return model ? model.status : 'completed';
  };

  const renderBox = (name) => {
    const status = getStatus(name);
    const borderColor = getBorderColor(status);

    return (
      <Paper
        elevation={3}
        style={{
          padding: '16px',
          // color: 'white',
          textAlign: 'center',
          border: `2px solid ${borderColor}`,
          backgroundColor: borderColor
        }}
      >
        <Typography>{name}</Typography>
      </Paper>
    );
  };

  const renderArrow = (direction = 'right') => {
    return direction === 'right' ? <ArrowForwardIcon /> : <ArrowDownwardIcon style={{ fontSize: '30px' }} />;
  };

  return (
    <Grid container spacing={2} style={{ padding: '20px' }}>
      {/* Row 1 */}
      <Grid item xs={2}>{renderBox('download 1')}</Grid>
      <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {renderArrow()}
      </Grid>
      <Grid item xs={2}>{renderBox('invalid image 1')}</Grid>
      <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {renderArrow()}
      </Grid>
      <Grid item xs={2}>{renderBox('download 2')}</Grid>
      <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {renderArrow()}
      </Grid>
      <Grid item xs={2}>{renderBox('invalid image 2')}</Grid>

      {/* Row 2 */}
      <Grid item xs={2} style={{ marginTop: '20px' }}>{renderBox('download 3')}</Grid>
      <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
        {renderArrow()}
      </Grid>
      <Grid item xs={2} style={{ marginTop: '20px' }}>{renderBox('invalid image 3')}</Grid>
      <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
        {renderArrow()}
      </Grid>
      <Grid item xs={2} style={{ marginTop: '20px' }}>{renderBox('upload image')}</Grid>
      <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
        {renderArrow()}
      </Grid>
      <Grid item xs={2} style={{ marginTop: '20px' }}>{renderBox('update image')}</Grid>

      {/* Row 3 */}
      <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px', marginLeft: '35%' }}>
        {renderArrow()}
      </Grid>
      <Grid item xs={2} style={{ marginTop: '20px', marginLeft: '-2%' }}>{renderBox('classifier')}</Grid>

      {/* Additional Rows */}
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Grid container item xs={9} spacing={2} justifyContent="space-around">
          <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {renderArrow('down')}
            {renderBox('segment')}
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {renderArrow('down')}
            {renderBox('description batch')}
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {renderArrow('down')}
            {renderBox('jina embedding')}
          </Grid>
        </Grid>
      </Grid>


      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop:"20px" }}>
         <Grid container item xs={0} spacing={0} justifyContent="space-around">
             <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
             <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {renderArrow('down')}
            {renderBox('change name')}
          </Grid>
             </Grid>
             <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
             <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {renderArrow('down')}
            {renderBox('upload var image')}
          </Grid>
             </Grid>
         </Grid>
       </Grid>
    </Grid>
  );
};

export default Flowchart;
