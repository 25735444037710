import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Spinner from 'react-bootstrap/Spinner';
import { postRequest } from "../../Services/networkRequests";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DailyScrapperAction = () => {
    const titleStyle = { padding: '20px', width: '100%', margin: "0" }
    const [isLoadingScraperData, setIsLoadingScraperData] = useState(false)
    const [isburberryDaily, setIsBurberryDaily] = useState(false)
    const [isGapDaily, setIsGapDaily] = useState(false)
    const [isBananaDaily, setIsBananaDaily] = useState(false)
    const [isGucciDaily, setIsGucciDaily] = useState(false)
    const [isNikeDaily, setIsNikeDaily] = useState(false)
    const [isAdidasDaily, setIsAdidasDaily] = useState(false)
    const [isBershkaDaily, setIsBershkaDaily] = useState(false)
    const [isLululemonDaily, setIsLululemonDaily] = useState(false)
    const [isMangoDaily, setIsMangoDaily] = useState(false)
    const [isUniqloDaily, setIsUiqloDaily] = useState(false)
    // const [isForeverDaily, setIsForeverDaily] = useState(false)
    const [isOldNavyDaily, setIsOldNavyDaily] = useState(false)
    const [isLevisDaily, setIsLevisDaily] = useState(false)
    const [isHmDaily, setIsHmDaily] = useState(false)
    // const [isAsosDaily, setIsAsosDaily] = useState(false)
    const [isLacosteDaily, setIsLacosteDaily] = useState(false)
    const [isGivenchyDaily, setIsGivenchyDaily] = useState(false)
    const [isMassimoDaily, setIsMassimoDaily] = useState(false)
    // const [isSuperDryDaily, setIsSuperDryDaily] = useState(false)
    const [isZaraDaily, setIsZaraDaily] = useState(false)
    const [isAeDaily, setIsAeDaily] = useState(false)
    const [isaritziaDaily, setisaritziaDaily] = useState(false)
    const [isediktedDaily, setisediktedDaily] = useState(false)
    const [isprincessDaily, setisprincessDaily] = useState(false)
    const [isselfieDaily, setisselfieDaily] = useState(false)


    const runDailyScraper = async (e) => {
        setIsLoadingScraperData(true);
        e.preventDefault();
        let req = {
            "command": "start-queues-action"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsLoadingScraperData(false);
    }



    const burberryDaily = async (e) => {
        e.preventDefault();
        setIsBurberryDaily(true);
        let req = {
            "command": "start-burberry-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsBurberryDaily(false);


    }
    const gapDaily = async (e) => {
        e.preventDefault();
        setIsGapDaily(true);
        let req = {
            "command": "start-gap-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsGapDaily(false);


    }
    const bananaDaily = async (e) => {
        e.preventDefault();
        setIsBananaDaily(true);
        let req = {
            "command": "start-bnana-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsBananaDaily(false);
    }
    const gucciDaily = async (e) => {
        e.preventDefault();
        setIsGucciDaily(true);
        let req = {
            "command": "start-gucci-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsGucciDaily(false);
    }
    const nikeDaily = async (e) => {
        e.preventDefault();
        setIsNikeDaily(true);
        let req = {
            "command": "start-nike-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsNikeDaily(false);
    }
    const adidasDaily = async (e) => {
        e.preventDefault();
        setIsAdidasDaily(true);
        let req = {
            "command": "start-adidas-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsAdidasDaily(false);
    }
    const bershkaDaily = async (e) => {
        e.preventDefault();
        setIsBershkaDaily(true);
        let req = {
            "command": "start-bershka-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsBershkaDaily(false);
    }
    const lululemonDaily = async (e) => {
        e.preventDefault();
        setIsLululemonDaily(true);
        let req = {
            "command": "start-lululemon-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsLululemonDaily(false);
    }
    const mangoDaily = async (e) => {
        e.preventDefault();
        setIsMangoDaily(true);
        let req = {
            "command": "start-mango-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsMangoDaily(false);
    }
    const uniqloDaily = async (e) => {
        e.preventDefault();
        setIsUiqloDaily(true);
        let req = {
            "command": "start-uniqlo-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsUiqloDaily(false);
    }
    // const foreverDaily = async (e) => {
    //     e.preventDefault();
    //     setIsForeverDaily(true);
    //     let req = {
    //         "command": "start-forever-live-price"
    //     }
    //     const result = await postRequest('/trigger-command', req);
    //     if (result.status === 200) {
    //         toast(result.data.message);
    //     } else {
    //         toast('Something went wrong');
    //     }
    //     setIsForeverDaily(false);
    // }
    const oldNavyDaily = async (e) => {
        e.preventDefault();
        setIsOldNavyDaily(true);
        let req = {
            "command": "start-old-navy-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsOldNavyDaily(false);
    }
    const levisDaily = async (e) => {
        e.preventDefault();
        setIsLevisDaily(true);
        let req = {
            "command": "start-levi-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsLevisDaily(false);
    }
    const hmDaily = async (e) => {
        e.preventDefault();
        setIsHmDaily(true);
        let req = {
            "command": "start-hm-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsHmDaily(false);
    }
    // const asosDaily = async (e) => {
    //     e.preventDefault();
    //     setIsAsosDaily(true);
    //     let req = {
    //         "command": "start-asos-live-price"
    //     }
    //     const result = await postRequest('/trigger-command', req);
    //     if (result.status === 200) {
    //         toast(result.data.message);
    //     } else {
    //         toast('Something went wrong');
    //     }
    //     setIsAsosDaily(false);
    // }
    const lacosteDaily = async (e) => {
        e.preventDefault();
        setIsLacosteDaily(true);
        let req = {
            "command": "start-lacoste-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsLacosteDaily(false);
    }
    const givenchyDaily = async (e) => {
        e.preventDefault();
        setIsGivenchyDaily(true);
        let req = {
            "command": "start-givenchy-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsGivenchyDaily(false);
    }
    const massimoDaily = async (e) => {
        e.preventDefault();
        setIsMassimoDaily(true);
        let req = {
            "command": "start-massimo-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsMassimoDaily(false);
    }
    // const superDryDaily = async (e) => {
    //     e.preventDefault();
    //     setIsSuperDryDaily(true);
    //     let req = {
    //         "command": "start-super-dry-live-price"
    //     }
    //     const result = await postRequest('/trigger-command', req);
    //     if (result.status === 200) {
    //         toast(result.data.message);
    //     } else {
    //         toast('Something went wrong');
    //     }
    //     setIsSuperDryDaily(false);
    // }
    const zaraDaily = async (e) => {
        e.preventDefault();
        setIsZaraDaily(true);
        let req = {
            "command": "start-zara-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsZaraDaily(false);
    }
    const aritziaDaily = async (e) => {
        e.preventDefault();
        setisaritziaDaily(true);
        let req = {
            "command": "start-aritzia-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setisaritziaDaily(false);


    }

    const ediktedDaily = async (e) => {
        e.preventDefault();
        setisediktedDaily(true);
        let req = {
            "command": "start-edikted-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setisediktedDaily(false);


    }

    const princessDaily = async (e) => {
        e.preventDefault();
        setisprincessDaily(true);
        let req = {
            "command": "start-princess-polly-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setisprincessDaily(false);


    }

    const selfieDaily = async (e) => {
        e.preventDefault();
        setisselfieDaily(true);
        let req = {
            "command": "start-selfie-leslie-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setisselfieDaily(false);


    }

    const aeDaily = async (e) => {
        e.preventDefault();
        setIsAeDaily(true);
        let req = {
            "command": "start-ae-live-price"
        }
        const result = await postRequest('/trigger-command', req);
        if (result.status === 200) {
            toast(result.data.message);
        } else {
            toast('Something went wrong');
        }
        setIsAeDaily(false);


    }
    return (
        <div>
            <ToastContainer />
            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                <h3 style={titleStyle}>Daily Scrapper Actions</h3>
            </Box>
            <Box component={'div'} display={'flex'}>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => runDailyScraper(e)}>
                                    {isLoadingScraperData ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Run Daily Scrapers'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>



                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => burberryDaily(e)}>

                                    {isburberryDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Burberry Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => aritziaDaily(e)}>

                                    {isaritziaDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Aritzia Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => ediktedDaily(e)}>

                                    {isediktedDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Edikted Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => princessDaily(e)}>

                                    {isprincessDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Princess Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => selfieDaily(e)}>

                                    {isselfieDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Selfie Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => gapDaily(e)}>

                                    {isGapDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start gap Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => bananaDaily(e)}>

                                    {isBananaDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Banana Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => gucciDaily(e)}>

                                    {isGucciDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Gucci Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => nikeDaily(e)}>

                                    {isNikeDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Nike Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => adidasDaily(e)}>

                                    {isAdidasDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Adidas Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => bershkaDaily(e)}>

                                    {isBershkaDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Bershka Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => lululemonDaily(e)}>

                                    {isLululemonDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Lululemon Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => mangoDaily(e)}>

                                    {isMangoDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Mango Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => uniqloDaily(e)}>

                                    {isUniqloDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Uniqlo Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    {/* <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => foreverDaily(e)}>

                                    {isForeverDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Forever Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid> */}
                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => oldNavyDaily(e)}>

                                    {isOldNavyDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Old Navy Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => levisDaily(e)}>

                                    {isLevisDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        "Start Levi's Daily"
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => hmDaily(e)}>

                                    {isHmDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start H&m Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    {/* <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => asosDaily(e)}>

                                    {isAsosDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Asos Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid> */}
                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => lacosteDaily(e)}>

                                    {isLacosteDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Lacoste Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => givenchyDaily(e)}>

                                    {isGivenchyDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Givenchy Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => massimoDaily(e)}>

                                    {isMassimoDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Massimo Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    {/* <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => superDryDaily(e)}>

                                    {isSuperDryDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Super dry Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid> */}
                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => zaraDaily(e)}>

                                    {isZaraDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Zara Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper sx={{ width: '100%', mb: 2, padding: 5 }}>
                            <Box component={'div'} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                <Button variant="text" style={{ backgroundColor: '#70cad1', color: '#fff' }} onClick={(e) => aeDaily(e)}>

                                    {isAeDaily ? (
                                        <>
                                            <Spinner
                                                animation="border"
                                                size="sm"
                                                variant="light"
                                            />
                                        </>
                                    ) : (
                                        'Start Ae Daily'
                                    )}

                                </Button>

                            </Box>
                        </Paper>
                    </Grid>


                </Grid>
            </Box>

        </div>
    )
}
export default DailyScrapperAction;